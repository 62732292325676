import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl, intlShape, defineMessages, FormattedMessage} from 'react-intl';
import styles from './coalaboard-info.css';
import bindAll from 'lodash.bindall';

import Box from '../box/box.jsx';

const messages = defineMessages({
    userinput: {
        id: 'gui.coalaboard.simulator.userinput',
        description: '',
        defaultMessage: 'user input'
    },
    potentiometer: {
        id: 'gui.coalaboard.simulator.potentiometer',
        description: '',
        defaultMessage: 'potentiometer'
    },
    mic: {
        id: 'gui.coalaboard.simulator.mic',
        description: '',
        defaultMessage: 'mic'
    },
    ir_sensor: {
        id: 'gui.coalaboard.simulator.ir_sensor',
        description: '',
        defaultMessage: 'IR sensor'
    },
    temperature_sensor: {
        id: 'gui.coalaboard.simulator.temperature_sensor',
        description: '',
        defaultMessage: 'temperature sensor'
    },
    brightness_sensor: {
        id: 'gui.coalaboard.simulator.brightness_sensor',
        description: '',
        defaultMessage: 'brightness sensor'
    },
    button: {
        id: 'gui.coalaboard.simulator.button',
        description: '',
        defaultMessage: 'Button'
    },
    button_pressed: {
        id: 'gui.coalaboard.simulator.button_pressed',
        description: '',
        defaultMessage: 'Pressed'
    },
    button_not_pressed: {
        id: 'gui.coalaboard.simulator.button_not_pressed',
        description: '',
        defaultMessage: 'Released'
    },
    not_connected: {
        id: 'gui.coalaboard.simulator.not_connected',
        description: '',
        defaultMessage: 'Not connected'
    },
});

// 0x01   밝기센서    brightness
// 0x02   거리센서    distance
// 0x03   버튼        button
// 0x04   가변저항    potentiometer
// 0x05   사운드      sound
// 0x06   울트라소닉  ultrasonic
// 0x0A   진동센서    vibration
// 0x0B   사용자입력  userinput
// 0x12   디씨모터    dcmotor    
// 0x13   서보모터    servomotor

class CoalaBoardInfo extends React.Component {

    constructor( props ) {
        super(props);
        bindAll(this, [
        ]);

        this.SENSOR_ITEMS       = [
            { name: this.props.intl.formatMessage(messages.userinput),          val: 0x0B },
            { name: this.props.intl.formatMessage(messages.potentiometer),      val: 0x04 },
            { name: this.props.intl.formatMessage(messages.mic),                val: 0x05 },
            { name: this.props.intl.formatMessage(messages.ir_sensor),          val: 0x02 },
            { name: this.props.intl.formatMessage(messages.temperature_sensor), val: 0x07 },
            { name: this.props.intl.formatMessage(messages.brightness_sensor),  val: 0x01 },
            { name: this.props.intl.formatMessage(messages.button),             val: 0x03 }
        ]
    }

    getValueBySensorType( portItems, type ) {
        for( let i = 0; i < portItems.length; i++ ) {
            if (portItems[i].type == type) {
                return portItems[i].val;
            }
        }
        return -1;
    }

    shouldComponentUpdate( nextProps ) {
        return ( true );
    }
    
    render () {
        // portIntoItems
        //  keys: port, type, val
        
        let     portItems       = this.props.coalaBoardData.portIntoItems;

        if( portItems == undefined ) {
            portItems = [ 
                { "port" : "1",   "type" : 0x00, "val": 0x00 },
                { "port" : "2",   "type" : 0x00, "val": 0x00 },
                { "port" : "3",   "type" : 0x00, "val": 0x00 },
                { "port" : "4",   "type" : 0x00, "val": 0x00 }, 
                { "port" : "A",   "type" : 0x00, "val": 0x00 }, 
                { "port" : "B",   "type" : 0x00, "val": 0x00 }, 
                { "port" : "C",   "type" : 0x00, "val": 0x00 }, 
                { "port" : "D",   "type" : 0x00, "val": 0x00 }, 
                { "port" : "E_R", "type" : 0x00, "val": 0x00 },
                { "port" : "E_G", "type" : 0x00, "val": 0x00 },
                { "port" : "E_B", "type" : 0x00, "val": 0x00 }
              ];
        }

        const   iconItems       = [];
        const   valueItems      = [];

        // Sensor and Motors
        // let _visibility = ( portItems[i].type == 0x00 ? "hidden" : "visible" );
        // {portItems[i].val}
        // for( let i = 0; i < 6; i++ ) {

        //     valueItems.push( 
        //         <div key={"value_" + i.toString()} className={BB_UI_INFO[ i ].val_div_style}  >
        //             <label className={BB_UI_INFO[ i ].val_label_style} style={{ visibility : _visibility }}>{portItems[i].val}</label>
        //         </div>
        //     );
        // }

        // LED
        const   _visibilityLED  = ( portItems[8].type == 0x00 ? "hidden" : "visible" );
        const   led_red         = portItems[ 8].val;
        const   led_green       = portItems[ 9].val;
        const   led_blue        = portItems[10].val;

        const   sensorValues      = [];
        for( let i = 0; i < this.SENSOR_ITEMS.length; i++ ) {
            let  valueName  = "";
            if( this.SENSOR_ITEMS[i].val == 0x03 ) {
                // 버튼인 경우
                let v       =   portItems[ this.SENSOR_ITEMS[i].val ];
                valueName   =   ( v.val < 100 ? this.props.intl.formatMessage(messages.button_pressed) : this.props.intl.formatMessage(messages.button_not_pressed) );
            } else {
                let v       =   this.SENSOR_ITEMS[i].val;
                valueName   =   this.getValueBySensorType(portItems, v);
            }
            if ( valueName == -1 ) {
                valueName = this.props.intl.formatMessage(messages.not_connected);
            }
            sensorValues.push(
                <div key={i} className={styles.sensor_label}>
                    <span className={styles.sensor_name_label}>{this.SENSOR_ITEMS[i].name}</span>
                    <span className={styles.sensor_value_label}>{valueName}</span>
                </div>
            );            
        }                       
        return (
            <Box className={styles.spriteInfo}>
                {sensorValues}
            </Box>
        );
    }
}

CoalaBoardInfo.propTypes = {
    coalaBoardData: PropTypes.shape({
        sensorName: PropTypes.string,
        isConnected: PropTypes.bool,
        visItems: PropTypes.arrayOf(PropTypes.string),
        imgItems: PropTypes.arrayOf(PropTypes.string),
        valItems: PropTypes.arrayOf(PropTypes.string),
    })
};

export default injectIntl(CoalaBoardInfo);
