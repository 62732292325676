import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';

import CoalaBoardInfoComponent from '../components/coalaboard/coalaboard-info.jsx';

class CoalaBoardInfo extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            '_onConnected',
            '_onDisconnected',
            '_onMessageCallback'
            ]
        );
        this.coalaBoardData = this.initCoalaBoardData();
    }

    initCoalaBoardData() {
        return { 
            sensorName : "coalaboard",
            isConnected : false,
         };
    }

    componentWillMount() {
        this.props.vm.addListener('PERIPHERAL_CONNECTED_COALABOARD_LINK',    this._onConnected);
        this.props.vm.addListener('PERIPHERAL_DISCONNECTED_COALABOARD_LINK', this._onDisconnected);
    }
    
    componentWillUnmount() {
        this.props.vm.removeListener('PERIPHERAL_CONNECTED_COALABOARD_LINK',    this._onConnected);
        this.props.vm.removeListener('PERIPHERAL_DISCONNECTED_COALABOARD_LINK', this._onDisconnected);

        let extentionId = this.props.vm.extensionManager._loadedExtensions.get( "coalaboard" );
        if( extentionId !== undefined ) {
            let coalaBoardExtentionObject   = this.props.vm.extensionManager.getServices()[ extentionId ];
            if( coalaBoardExtentionObject !== null && coalaBoardExtentionObject !== undefined ) {
                coalaBoardExtentionObject.removeListener( "message", this._onMessageCallback );
                coalaBoardExtentionObject.disconnect();
            }
        }
    }

    /**
     * 하드웨어가 연결(즉, COM3 연결) 되었을 때, CallBack 함수
     */
    _onConnected() {
        this.coalaBoardData = this.initCoalaBoardData();
        this.coalaBoardData.isConnected = true;
        this.setState( { key: Math.random() } );

        let extentionId = this.props.vm.extensionManager._loadedExtensions.get( "coalaboard" );
        if( extentionId !== undefined ) {
            let coalaBoardExtentionObject   = this.props.vm.extensionManager.getServices()[ extentionId ];
            if( coalaBoardExtentionObject !== null && coalaBoardExtentionObject !== undefined ) {
                coalaBoardExtentionObject.addListener( "message", this._onMessageCallback );
            }
        }
    }
    
    /**
     * 하드웨어가 연결해제(즉, COM3 연결해제) 시, CallBack 함수. 
     */
    _onDisconnected() {
        let extentionId = this.props.vm.extensionManager._loadedExtensions.get( "coalaboard" );
        if( extentionId !== undefined ) {
            let coalaBoardExtentionObject   = this.props.vm.extensionManager.getServices()[ extentionId ];
            if( coalaBoardExtentionObject !== null && coalaBoardExtentionObject !== undefined ) {
                coalaBoardExtentionObject.removeListener( "message", this._onMessageCallback );
            }
        }
        this.coalaBoardData = this.initCoalaBoardData();
        this.setState( { key: Math.random() } );
    }
        
    _onMessageCallback( portIntoItems ) {
        this.coalaBoardData.portIntoItems = portIntoItems;
        this.setState( { key: Math.random() } );
    }
        
    render () {
        return (
            <CoalaBoardInfoComponent
                {...this.props}
                coalaBoardData={this.coalaBoardData}
            />
        );
    }
}

CoalaBoardInfo.propTypes = {
    ...CoalaBoardInfoComponent.propTypes,
    coalaBoardData: PropTypes.shape({
        sensorName: PropTypes.string,
        isConnected: PropTypes.bool
    })
};

export default CoalaBoardInfo;