
import bindAll from 'lodash.bindall';
import * as p5 from "p5";
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import MLAudioEditorComponent from '../components/mlaudio-editor/mlaudio-editor.jsx';
import { activateTab, BLOCKS_TAB_INDEX } from '../reducers/editor-tab';
import { projectTitleInitialState } from '../reducers/project-title';

const messages = defineMessages({
    class: {
        id: 'gui.mlaudio.class',
        description: '',
        defaultMessage: "Class"
    },    
    msg1: {
        id: 'gui.mlaudio.msg1',
        description: '',
        defaultMessage: 'Learn after creating at least 2 classes with sample audio added.'
    },
    msg2: {
        id: 'gui.mlaudio.msg2',
        description: '',
        defaultMessage: 'Learn after adding sample audio to your class.'
    },
    msg3: {
        id: 'gui.mlaudio.msg3',
        description: '',
        defaultMessage: 'Do you like to create a new audio learning model?'
    },
    msg4: {
        id: 'gui.mlaudio.msg4',
        description: '',
        defaultMessage: "Click 'Add Extension Button' in the Code tab to add the Macine Learning audio block."
    }
});


class MLAudioEditor extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleOpen',
            'handleSave',
            'handleSaveFile',
            'handleEdit',
            'handleAddClass',
            'handleClassOptionClick',
            'handleDeleteClass',
            'handleDisableClass',
            'handleRemoveAllSample',
            'handleDownloadSample',
            'handleHidePopup',
            'handleShowWebCam',
            'handleCloseWebCam',
            'handleImageUploadHideShow',
            'handleClassNameChange',
            'handleBtnEditName',
            'handleKeydownEnter',
            'handleUploadImages',
            'handleResetPage',
            'handleBtnAdvancedCollapseClick',
            'handleAudioRecord',
            'handleApplyAudioRecord',
            'handleTrain',
            'handleEpoch',
            'handleBatchSize',
            'handleLearningRate',
            'handleResetDefaults',
            'handleApplyModel',
            'handlePreviewWebCam',
            'trainingCallback',
            '_getExtention',
            'startPreviewRecongnition',
            'stopPreviewRecongnition',
            '_processPreviewInternal',
            'createMLData',
            'handleDeleteImageSample',
            'handleAddedClassUploadImage',
            'handleAddedPreviewUploadImage',
            'handleBtnTermHelp',
            'stopAutdioRecordThread',
            'updateRecordAudioUI',
            'handleChangedMicOnOff'
        ]);
        this.className = this.props.intl.formatMessage(messages.class);
        this.state = this.createMLData();

        this._audioRecordIntervalId = null;
    }

    createMLData() {
        return {
            class: [
                {
                    className: this.className + '1',
                    content: 'Add Images Sample',
                    images: [],
                    showOptionPopup: false,
                    showWebCam: false,
                    showFileUpload: false,
                    isEditing: false,
                    recordStatus: "INIT",
                    webCam: {
                        canvas: null,
                        myp5: null,
                        images: [],     // images[0] = { "id" : "클래스1-0", "data" : "~~~" }
                        tempSpectrumItems: [],       // (43 x 232 배열, 1초)의 임시 배열, 적용하기 전까지는, 여기에 저장
                        spectrumItems: [],           // (43 x 232 배열, 1초)의 배열, spectrumItems 이 20개면, 20초다.
                        tempWaveformItems: [],
                        waveformItems: []
                    }
                },
                {
                    className: this.className + '2',
                    content: 'Add Images Sample',
                    images: [],
                    showOptionPopup: false,
                    showWebCam: false,
                    showFileUpload: false,
                    isEditing: false,
                    recordStatus: "INIT",
                    webCam: {
                        canvas: null,
                        myp5: null,
                        images: [],
                        tempSpectrumItems: [],       // (43 x 232 배열, 1초)의 임시 배열, 적용하기 전까지는, 여기에 저장
                        spectrumItems: [],            // (43 x 232 배열, 1초)의 배열, spectrumItems 이 20개면, 20초다.
                        tempWaveformItems: [],
                        waveformItems: []
                    }
                }
            ],
            trainingTab: {
                trainingStatus : "INIT",
                isAdvancedCollapse: false,
                learningRate: 5,
                epochs: 30,
                batchSize: 1,
                showOptionPopupEpoch: false,
                showOptionPopupBatchSize: false,
                showOptionPopupLearning: false,
                currentEpoch: 0,
                totalEpoch: 30,
                loss: "0.0",
                graphItems: [{ epoch: 0,  value: 100} ]
            },
            previewTab: {
                showWebCam: false,
                webCam: {
                    canvas: null,
                    myp5: null,
                    image: null
                },
                previewStatus : "INIT",
                micOn : false
            },
            previewResults: []
        };
    }

    // componentDidMount () {
    //     console.info("aaa");
    // }

    /**
     * ML 이미지 말고 다른 탭을 눌렀을 때, 오디오 모두 끈다.( 성능을 위해서 )
     * @param {*} newProps 
     */
    componentWillReceiveProps (newProps) {
        let _newClass = [...this.state.class];
        // 나머지 카메라 끄기
        for( let i = 0; i < _newClass.length; i++ ) {
            if ( _newClass[ i ].showWebCam ) {
                this.webCamSetup( _newClass, i );
            }
        }

        this.stopPreviewRecongnition();

        this.state.trainingTab.trainingStatus = "INIT";
        this.state.previewTab.previewStatus   = "INIT";
        if( this.state.previewTab.showWebCam ) {
            this.state.previewTab.showWebCam      = false;
            try{
                this.state.previewTab.webCam.myp5.init();
                this.state.previewTab.webCam.myp5.remove();
                this.state.previewTab.webCam.myp5   = null;
            } catch( e ) {
            }
            try{
                this.state.previewTab.webCam.canvas.remove();
                this.state.previewTab.webCam.canvas = null;
            } catch( e ) {
            }
        }
        this.state.previewResults   = [];
        this.setState({
            class: _newClass,
            trainingTab: this.state.trainingTab,
            previewTab:  this.state.previewTab,
            previewResults: []
        });
    }
    // componentWillUnmount () {
    //     console.info("ccc");
    // }

    // ================================
    
    handleOpen ( fileDataText ) {
        const extention = this._getExtention();

        // 기존 UI 초기화
        {
            this.stopPreviewRecongnition();

            for( let i = 0; i < this.state.class.length; i++ ) {
                const clazz = this.state.class[ i ];
                if( clazz.webCam.canvas ) {
                    clazz.webCam.canvas.remove();
                }
                if ( clazz.webCam.myp5 ) {
                    clazz.webCam.myp5.remove();
                }
            }

            const pt = this.state.previewTab;
            pt.showWebCam   = false;
            if( pt.webCam.canvas ) {
                pt.webCam.canvas.remove();
            }
            if( pt.webCam.myp5 ) {
                pt.webCam.myp5.remove();
            }
            this.state = this.createMLData();
            this.state.trainingTab.currentEpoch   = 0; 
            this.state.trainingTab.totalEpoch     = this.state.trainingTab.epochs;             
            this.state.trainingTab.graphItems     = [{ epoch: 0,  value: 100}];
            this.state.trainingTab.loss           = "0.0";
            this.setState({
                class: this.state.class,
                trainingTab: this.state.trainingTab,
                previewTab: this.state.previewTab,
                previewResults: this.state.previewResults
            });            
        }

        // 불러오기
        {
            const mldata    = this.createMLData();
            mldata.class    = [];
    
            const openFileJson  = JSON.parse( fileDataText );
    
            // 클래스 세팅
            for( let i = 0; i < openFileJson.classItems.length; i++ ) {
                const classItem = openFileJson.classItems[ i ];
    
                const spec_data = [];
                for( let j = 0; j < classItem.webCam.specItems.length; j++ ) {
                    spec_data.push( 
                        { 
                            id   : classItem.className + "-" + Math.floor( Math.random() * 10000000000000 ), 
                            data : classItem.webCam.specItems[ j ]
                        });
                }

                const wave_data = [];
                for( let j = 0; j < classItem.webCam.waveItems.length; j++ ) {
                    wave_data.push( 
                        { 
                            id   : classItem.className + "-" + Math.floor( Math.random() * 10000000000000 ), 
                            data : classItem.webCam.waveItems[ j ]
                        });
                }

                const item = 
                {
                    className: classItem.className,
                    content: classItem.content,
                    images: [],
                    showOptionPopup: false,
                    showWebCam: false,
                    showFileUpload: false,
                    isEditing: false,
                    recordStatus: "INIT",
                    webCam: {
                        canvas: null,
                        myp5: null,
                        images: [],
                        tempSpectrumItems: [],       
                        spectrumItems: spec_data,    
                        tempWaveformItems: [],
                        waveformItems: wave_data                        
                    }
                };
                mldata.class.push( item );
            }
    
            // 학습하기 세팅, kevin, 2020-12-05, 불러오기 시, learningRate, epochs는 세팅하지 않고, 디폴트로 쓴다.
            // mldata.trainingTab.learningRate     =   openFileJson.trainingTab.learningRate;
            // mldata.trainingTab.epochs           =   openFileJson.trainingTab.epochs;
            
            this.state.class        = mldata.class;
            this.state.trainingTab  = mldata.trainingTab;
    
            this.setState({
                class: mldata.class,
                trainingTab: mldata.trainingTab
            })
        }

    }

    handleSave () {

    }    

    handleSaveFile() {
        return new Promise( resolve => {
            
            const extention = this._getExtention();

            const fileData  = {
                classItems : [],
                trainingTab : {
                    learningRate : 0,
                    epochs : 0,
                }
            };

            // 클래스 세팅
            for( let i = 0; i < this.state.class.length; i++ ) {
    
                const spec_data = [];
                for( let j = 0; j < this.state.class[ i ].webCam.spectrumItems.length; j++ ) {
                    
                    // FloatArray 9976
                    const fItems = this.state.class[ i ].webCam.spectrumItems[ j ].data;
                    spec_data.push( Array.from( fItems ) );
                }
                
                
                const wave_data = [];
                for( let j = 0; j < this.state.class[ i ].webCam.waveformItems.length; j++ ) {

                    // FloatArray 9976
                    const fItems = this.state.class[ i ].webCam.waveformItems[ j ].data;
                    wave_data.push( Array.from( fItems ) );
                }
    
                const classData = {
                    className: this.state.class[ i ].className,
                    content:   this.state.class[ i ].content,
                    webCam: {
                        specItems: spec_data,
                        waveItems: wave_data,
                    }            
                }
                fileData.classItems.push( classData );
            }
    
            // 학습하기 세팅
            fileData.trainingTab.learningRate   =   this.state.trainingTab.learningRate;
            fileData.trainingTab.batchSize      =   this.state.trainingTab.batchSize;
            fileData.trainingTab.epochs         =   this.state.trainingTab.epochs;

            const blob = new Blob([JSON.stringify(fileData, null, 2)], {type : 'application/json'});
            
            if (window.bitbrick) {
                var _this2 = this;
                var arrayBuffer, uint8Array;
                var fileReader = new FileReader();
                fileReader.onload = function() {
                    arrayBuffer = this.result;
                    uint8Array  = new Uint8Array(arrayBuffer);
                    const data_size = uint8Array.length;
                    const paketSize = 1024 * 32;
                    const s_count = (data_size / paketSize);

                    //if(s_count > 1)
                    {
                        var is_save = true;
                        var vInt = parseInt(s_count);

                        var projectFilename;
                        if( _this2.props.projectFilename && ( _this2.props.projectFilename !== '' ) ) {
                            projectFilename = _this2.props.projectFilename;
                        } else {
                            projectFilename = "Scratch3";
                        }

                        for (var i = 0; i < vInt; i++) {

                            const resJson_save = JSON.parse( window.bitbrick.save_audio( projectFilename, "mla",i, vInt+1, paketSize, new Uint8Array( uint8Array.slice(i*paketSize,(i+1)*paketSize) ) ));

                            if( resJson_save.result ) {
                                // 정상
                            } else {
                                // Save 에러,  안드로이드 저장 권한 체크가 않된 경우 발생         
                                console.error( "save error - " + resJson_save );
                                is_save = false;
                                break;
                            }
                        }
                        if(is_save)    
                        {
                            const resJson_save = JSON.parse( window.bitbrick.save_audio( projectFilename, "mla",vInt, vInt+1,data_size -  vInt*paketSize, new Uint8Array( uint8Array.slice(vInt*paketSize, data_size) ) ));

                            if( resJson_save.result ) {
                                // 정상
                            } else {
                                // Save 에러,  안드로이드 저장 권한 체크가 않된 경우 발생         
                                console.error( "save error - " + resJson_save );
                            }
                        }
                        

                    }
                   
                   // var array_tttt = new Uint8Array([0x04, 0x06, 0x07, 0x08]);
                   

                };
                fileReader.readAsArrayBuffer(blob);
                                
            } else {
                resolve( blob );
            }
        });     
    }


    // ================================


    handleEdit (e) {
    }

    handleAddClass (e) {
        this.handleHidePopup(e);

        let index   = 1;
        for( let i = 0; i < this.state.class.length; i++ ) {
            let clazzName = this.state.class[ i ].className;
            if( clazzName.indexOf( this.className ) == 0 ) {
                // 기존에 있는 클래스 이름에서 중복된것을 찾아서, 중복되지 않게 한다.
                let _i = parseInt( clazzName.substring( this.className.length, clazzName.length ) );
                if( index <= _i ) {
                    index = _i + 1;
                }
            } 
        }
        const mldata = this.createMLData();
        mldata.class[ 0 ].className = ( this.className + index );

        let newClass = [
            ...this.state.class,
            mldata.class[ 0 ]
        ];
        this.setState({
            class: newClass
        })
    }
    handleClassOptionClick (index) {
        let newClass = [...this.state.class];
        newClass.forEach( (e, i) => { if (i != index) e.showOptionPopup = false })
        newClass[index].showOptionPopup = newClass[index].showOptionPopup?false:true;
        this.setState({
            class: newClass
        });
    }

    // {
    //     className: '클래스1',
    //     content: 'Add Images Sample',
    //     images: [],
    //     showOptionPopup: false,
    //     showWebCam: false,
    //     showFileUpload: false,
    //     isEditing: false,
    //     webCam: {
    //         canvas: null,
    //         myp5: null,
    //         images: [],     // images[0] = { "id" : 0, "data" : "~~~" }
    //     }
    // }
    handleDeleteClass (index) {
        let newClass1 = [...this.state.class];
        newClass1[ index ].showWebCam   = false;
        newClass1[ index ].images           = [];
        newClass1[ index ].webCam.spectrumItems    = [];
        newClass1[ index ].webCam.waveformItems    = [];
        newClass1.forEach( e => { 
            if( e.webCam.canvas ) {
                e.webCam.canvas.remove();
            }
            if ( e.webCam.myp5 ) {
                e.webCam.myp5.remove();
            }
            e.images          = [...e.images];
            e.webCam.spectrumItems   = [...e.webCam.spectrumItems];
            e.webCam.waveformItems   = [...e.webCam.waveformItems];
            e.showOptionPopup = false;
            e.showWebCam      = false;
            e.showFileUpload  = false;
            e.isEditing       = false;            
        });
        this.setState({
            class: newClass1
        }, () => {
            let newClass2 = [...this.state.class];
            newClass2.splice(index, 1)
            this.setState({
                class: newClass2
            });
        });
    }
    
    handleDisableClass (index) {

    }

    handleRemoveAllSample (index) {
        this.handleHidePopup();
        let newClass = [...this.state.class];
        if( newClass[index].webCam ) {
            newClass[index].webCam.spectrumItems = [];
            newClass[index].webCam.waveformItems = [];
        }
        this.setState({
            class: newClass
        });
    }

    handleDownloadSample (index) {
    
    }

    handleTrain() {
        this.handleHidePopup();

        let classImageCount     = 0;
        let noImageClassItems   = [];
        // 나머지 카메라 끄기
        for( let i = 0; i < this.state.class.length; i++ ) {
            if( this.state.class[ i ].webCam.spectrumItems.length == 0  ) {
                noImageClassItems.push( this.state.class[ i ].className );
            } else {
                classImageCount += 1;   // 클래스별로 이미지가 있으면, 1을 더하여, 이미지가 있는 클래스 전체 개수가 2 이상이어야, 학습이 가능하기 때문.
            }
        }

        // 샘플 이미지가 있는 클래스가 최소 두개 이상이어야 학습이 된다. (ML5에서 에러남)
        if( classImageCount < 2 ) {
            alert( this.props.intl.formatMessage(messages.msg1) );
            return;
        }        

        // 클래스에 샘플 이미지가 없을때, 
        if( noImageClassItems.length > 0 ) {
            const alertMsg  = noImageClassItems.join();
            alert( this.props.intl.formatMessage(messages.msg2) );
            return;
        }

        // 초기상태와, 학습완료 상태만 학습하기를 할 수 있으며, 
        // PRE_TRAINING, TRAINING 중에는 학습하기를 다시 하지 않는다.
        if( !( this.state.trainingTab.trainingStatus == "INIT" ||
            this.state.trainingTab.trainingStatus == "TRAINED" ) ) {
            return;
        }

        this.stopPreviewRecongnition();

        let _newClass = [...this.state.class];
        // 나머지 카메라 끄기
        for( let i = 0; i < _newClass.length; i++ ) {
            if ( _newClass[ i ].showWebCam ) {
                this.webCamSetup( _newClass, i );
            }
        }
        
        this.state.trainingTab.trainingStatus = "PRE_TRAINING";
        this.state.trainingTab.currentEpoch   = 0; 
        this.state.trainingTab.totalEpoch     = this.state.trainingTab.epochs;             
        this.state.trainingTab.graphItems     = [{ epoch: 0,  value: 100}];
        this.state.trainingTab.loss           = "0.0";
        this.state.previewTab.previewStatus   = "INIT";
        this.state.previewTab.micOn           = false;
        if( this.state.previewTab.showWebCam ) {
            this.state.previewTab.showWebCam      = false;
            try{
                this.state.previewTab.webCam.myp5.init();
                this.state.previewTab.webCam.myp5.remove();
                this.state.previewTab.webCam.myp5   = null;
            } catch( e ) {
            }
            try{
                this.state.previewTab.webCam.canvas.remove();
                this.state.previewTab.webCam.canvas = null;
            } catch( e ) {
            }
        }

        this.state.previewResults   = [];
        this.setState({
            class: _newClass,
            trainingTab: this.state.trainingTab,
            previewTab:  this.state.previewTab,
            previewResults: []
        });

        let classNames  = [];
        let classItems  = [];
        let newClass    = [...this.state.class];
        for( let i = 0; i < newClass.length; i++ ) {
            let _class = newClass[ i ];
            classNames.push( _class.className );
            for( let j = 0; j < _class.webCam.spectrumItems.length; j++ ) {
                let classItem = {
                    name : _class.className,
                    data : _class.webCam.spectrumItems[ j ].data
                };
                classItems.push( classItem );                          
            }
        }
        // * [
        // *  {
        // *    "name" : "class1",
        // *    "data" : []
        // *  },
        // *  {
        // *    "name" : "class2",
        // *    "data" : []
        // *  },
        // * ]
        let learningRate    = this.state.trainingTab.learningRate;
        let trainingOptions = {
            epochs:     this.state.trainingTab.epochs,
            batchSize:  this.state.trainingTab.batchSize
        }

        setTimeout( ()=> {
            const extentionObject = this._getExtention();
            extentionObject
                .train( classNames, classItems, learningRate, trainingOptions, this.trainingCallback )
                .then( () => {
                    console.info( "training..." );
                }) ;
        }, 1000 );
    }

    /**
     * 
     * "INIT" status is not trained yet
     * 
     * @param {string} status   PRE_TRAINING : Prepare training
     *                          TRAINING     : Training
     *                          TRAINED      : Trained
     * @param {int} epochs  max epoch
     * @param {int} epoch   current epoch
     * @param {json} loss   value can be NaN
     *                      {
     *                          "acc"       : "",
     *                          "loss"      : "",
     *                          "val_acc"   : "",
     *                          "val_loss"  : "",
     *                      }
     */
    trainingCallback( status, epochs, epoch, loss ) {
        const ep  = JSON.stringify( epoch );
        const los = JSON.stringify( loss );
        console.info(`status=${status}, epochs=${epochs}, epoch=${ep}, loss=${los}` );
        
        this.state.trainingTab.trainingStatus = status;
        
        if( status == "TRAINED" ) {
            this.state.trainingTab.currentEpoch   = this.state.trainingTab.epochs; 
            this.state.trainingTab.totalEpoch     = this.state.trainingTab.epochs;             

            // Case1. 예측하기 - 카메라 선택 시
            // training 완료 일때, 카메라 켜고, 학습을 자동으로 한다.

            // 카메를 자동으로 켠다.
            this.handlePreviewWebCam( "on" );
            // 카메라는 움직이므로, 쓰레드로 분류를 계속 한다.
            this.startPreviewRecongnition();
        } else if( status == "TRAINING" ) {
            // training 중 일때, 에폭 숫자를 증가 시켜 보여준다.
            this.state.trainingTab.currentEpoch   = ( epoch + 1 ); 
            this.state.trainingTab.totalEpoch     = epochs;
            if( epoch != undefined ) {
                this.state.trainingTab.graphItems.push( { epoch : ( epoch + 1 ), value : ( loss.loss * 100 ).toFixed(1) } );
            }

            this.state.trainingTab.loss           = ( loss == undefined ? "0.0" : ( loss.loss * 100 ).toFixed(1) );
            this.setState({
                trainingTab: this.state.trainingTab
            });
        }

    }

    handleSampleImage ( thi, i, index, _item, _itemIndex ) {

        
        // let myp5 = new p5( p => {
        //     p.setup = function() {
        //         let constraints = {
        //             video: {
        //                 mandatory: {
        //                     // maxWidth: 250,
        //                     // maxHeight: 182,
        //                     maxWidth: 302,
        //                     maxHeight: 100,
        //                 }
        //             },  
        //             audio: false
        //         };
        
        //         let video = p.createCapture( constraints );
        //         video.size( 302, 100 );
        //         let cvs   = p.createCanvas( 302, 100 );
        //         cvs.hide();     // hide를 해야, UI가 깨지지 않고, video 아래에 canvas가 생기지 않음
        //         newClass[index].webCam.video = video;
        //         newClass[index].webCam.myp5  = p;
        //         //console.log('ml5: ', await ml5.bodyPix(video));
        //     }
        // }, 
        // webCamCanvasDiv
        // );
    }

    handleHidePopup (e) {
        let newClass = [...this.state.class];
        newClass.forEach( e => { e.showOptionPopup = false })
        this.setState({
            class: newClass
        });
    }

    webCamSetup ( newClass, index) {
        if (newClass[index].showWebCam) {
            if( newClass[index].webCam.myp5 ) {
                newClass[index].webCam.myp5.init();

                newClass[index].webCam.myp5.remove();
                newClass[index].webCam.canvas.remove();
            }
            newClass[index].showWebCam  = false;
        } else {
            // newClass[index].webCam.spectrumItems = [];
            // newClass[index].webCam.waveformItems = [];
            // this.setState({
            //     webCam: newClass
            // });             
            this.stopAutdioRecordThread( index );
            if( newClass[index].webCam.myp5 != null ) {
                newClass[index].webCam.myp5.init();
                newClass[index].webCam.myp5.remove();
                newClass[index].webCam.canvas.remove();
            }
            let spectogramAreadDiv = document.getElementById('spectogramArea_' + index);
            new p5( p => {
                let canvas;
                let offset = 0;
                let _spectrumData = [];
                const BASE_Y    = 60;
                const H_PIXEL   = 60;
                const W_PIXEL   = 66;
                const TOTAL_SEC = 2;
                const UNIT      = 6.6;
                const COUNT     = 6;

                const D_100ms   =  4403;        // 0.1초
                const D_2s      = 88064;        // 2초 데이터

                p.setup = function() {
                    p.noLoop();
                    canvas	= p.createCanvas( TOTAL_SEC * W_PIXEL, TOTAL_SEC * H_PIXEL );
                    canvas.style( 'border-radius', '5px' );
                    p.background('rgba(77,151,255,.5)');
                    p.noStroke();       // 
                    p.fill(224, 224, 224);      // 회색
                    p.rect(0, BASE_Y, canvas.width, 1 );     // 기본 그리드 가로선, 1은 선 두깨  

                    newClass[index].webCam.myp5     = p;
                    newClass[index].webCam.canvas   = canvas;
                };
                p.draw = function() {
                    // p.image(img, 0, 0);
                    // waveform data : 176128
                    // 176128 : 2 =  : 0.1

                    // 2x = 17612.8
                    //  x =  8806.4 / 6
                    //  x =  1467.0 
                    if( _spectrumData.length != 0 ) {
                        //0.00001 ~  -0.00001
                        //p.background('rgba(77,151,255,.5)');
                        p.noStroke();
                        p.fill(224, 224, 224);      // 회색
                        p.rect(0, BASE_Y, canvas.width, 1 );     // 기본 그리드 가로선, 1은 선 두깨

                        for (let i = 0; i < COUNT; i++){
                            let x = offset + i;
                            let y = p.map( _spectrumData[ parseInt( ( D_2s - D_100ms ) + D_100ms / UNIT * i ) ], -0.1, 0.1, -W_PIXEL, H_PIXEL );
                            
                            p.fill(3, 78, 161);             // 엷은 파랑색
                            p.rect(x, BASE_Y, 1, y );            // 진폭 막대 그래프 보여주기
                            p.rect(x, BASE_Y, 1, -1 * y );       // 진폭 막대 그래프 반대 진폭 보여주기

                            p.fill(3, 78, 161);             // 엷은 파랑색
                            p.rect(x, BASE_Y, 1, 1 );       // 현재 녹음 지점 그려주기, 2는 두깨
                            // console.info(`${x}, 60, 1, ${y}, ${_spectrumData[ parseInt( ( 176128 - 8806 ) + 8806 / UNIT * i ) ]}, ${ parseInt( (176128 - 8806 ) + 8806 / UNIT * i ) }, ${_spectrumData.length}` );
                        }

                        offset 	+= UNIT;
                    }
                };
                p.setSpectrumData = function(spectrumData) {
                    _spectrumData = spectrumData;
                },
                p.init = function() {
                    p.clear();          // 모두 지우기
                    p.background('rgba(77,151,255,.5)');
                    p.noStroke();       // 
                    p.fill(224, 224, 224);      // 회색
                    p.rect(0, BASE_Y, canvas.width, 1 );     // 기본 그리드 가로선, 1은 선 두깨          

                    offset = 0;
                }
            }, 
            spectogramAreadDiv
            );
            newClass[index].showWebCam  = true;
        }
    }



    stopAutdioRecordThread( index ) {
        clearInterval( this._audioRecordIntervalId );        
        this._audioRecordIntervalId = null;
    }

    handleApplyAudioRecord (index) {
        console.info( "handleApplyAudioRecord = " + index );
        let newClass = {...this.state.class};
        newClass[ index ].webCam.tempSpectrumItems.map( item => {
            newClass[ index ].webCam.spectrumItems.push( 
                { 
                    id: ( newClass[index].className + "-" + Math.floor( Math.random() * 10000000000000 ) ),
                    data: item.data
                }
            );
        });
        newClass[ index ].webCam.tempWaveformItems.map( item => {
            newClass[ index ].webCam.waveformItems.push( 
                { 
                    id: ( newClass[index].className + "-" + Math.floor( Math.random() * 10000000000000 ) ),
                    data: item.data
                }
            );            
        } );
        // newClass[ index ].webCam.tempSpectrumItems  = [];
        // newClass[ index ].webCam.myp5.init();
        this.setState({
            webCam: newClass
        });
    }

    handleAudioRecord (index) {
        let newClass = [ ...this.state.class ];
        console.info( "handleAudioRecord = " + index );
        const ext           = this._getExtention();
        const stopPointer   = this.stopAutdioRecordThread;
        const updatePointer = this.updateRecordAudioUI;

        if( newClass[ index ].recordStatus == "INIT" || 
            newClass[ index ].recordStatus == "STOP" || 
            newClass[ index ].recordStatus == "RECORDED" ) {
            
            newClass[ index ].webCam.tempSpectrumItems  = [];
            newClass[ index ].webCam.tempWaveformItems  = [];
            newClass[ index ].webCam.myp5.init();
            newClass[ index ].recordStatus = "RECORDING";
            this.setState({
                webCam: newClass
            });     

            let count = 0;
            ext.addMicDataListener( function( audioData ) {
                count += 1;
                if( count % 20 == 0 ) {
                    ext.removeMicDataListener();        // 음성을 다 받았으면, 해제해야 한다.
                    const sVals = audioData.spectrogram.bufferSync().values;   // vals = Float32Array(9976), 1초
                    const wVals = audioData.waveform.bufferSync().values
                    newClass[ index ].webCam.tempSpectrumItems.push(
                        { id : newClass[index].className + "-" + Math.floor( Math.random() * 10000000000000 ), data: sVals }
                        );
                    newClass[ index ].webCam.tempWaveformItems.push(
                        { id : newClass[index].className + "-" + Math.floor( Math.random() * 10000000000000 ), data: wVals }
                        );
                    newClass[index].webCam.myp5.setSpectrumData( wVals );
                    // 0.1 초마다 호출, 그래프를 그려준다.
                    newClass[index].webCam.myp5.redraw();
                    updatePointer( index, "RECORDED" );  
                    
                    stopPointer( index );
                } else {
                    //const vals = audioData.spectrogram.bufferSync().values;   // vals = Float32Array(9976), 1초
                    newClass[index].webCam.myp5.setSpectrumData( audioData.waveform.bufferSync().values );
                    // 0.1 초마다 호출, 그래프를 그려준다.
                    newClass[index].webCam.myp5.redraw();
                }
            });

            // 마이크 레코딩 시작 전, 레코딩을 시작한다.
            // 100ms 마다 실행되고, count를 세서, 10개 마다 1초로 간주하고, 버퍼를 저장한다.

            // this._audioRecordIntervalId =   setInterval( function() {
            //     // 0 또는 9976
            //     count += 1;
            //     if( count % 20 == 0 ) {
            //         // 1초 마다 호출 됨
            //         ext.getCaptureAudioData().then( ( audioData ) => {
            //             const sVals = audioData.spectrogram.bufferSync().values;   // vals = Float32Array(9976), 1초
            //             const wVals = audioData.waveform.bufferSync().values
            //             newClass[ index ].webCam.tempSpectrumItems.push(
            //                 { id : newClass[index].className + "-" + Math.floor( Math.random() * 10000000000000 ), data: sVals }
            //                 );
            //             newClass[ index ].webCam.tempWaveformItems.push(
            //                 { id : newClass[index].className + "-" + Math.floor( Math.random() * 10000000000000 ), data: wVals }
            //                 );
            //             newClass[index].webCam.myp5.setSpectrumData( wVals );
            //             // 0.1 초마다 호출, 그래프를 그려준다.
            //             newClass[index].webCam.myp5.redraw();
            //             updatePointer( index, "RECORDED" );
            //         });
            //         stopPointer( index );
            //     } else {
            //         ext.getCaptureAudioData().then( ( audioData ) => {
            //             //const vals = audioData.spectrogram.bufferSync().values;   // vals = Float32Array(9976), 1초
            //             newClass[index].webCam.myp5.setSpectrumData( audioData.waveform.bufferSync().values );
            //             // 0.1 초마다 호출, 그래프를 그려준다.
            //             newClass[index].webCam.myp5.redraw();
            //         });                    
            //     }
            // }, 100 );
        } else if( newClass[ index ].recordStatus == "RECORDING" ) {
            // 마이크 레코딩 중, 레코딩을 멈춘다.
            this.stopAutdioRecordThread( index );
            ext.stopCaptureAudio();
            this.updateRecordAudioUI( index, "STOP" );
        }
    }

    updateRecordAudioUI( index, status ) {
        let newClass = [ ...this.state.class ];
        newClass[ index ].recordStatus = status;
        this.setState({
            webCam: newClass
        });        
    }

    handleShowWebCam ( index ) {
        this.handleHidePopup();
        let newClass = [...this.state.class];
        // 나머지 카메라 끄기
        for( let i = 0; i < newClass.length; i++ ) {
            if ( newClass[ i ].showWebCam ) {
                this.webCamSetup( newClass, i );
            }
            newClass[ i ].showFileUpload = false;
        }
        // 카메라 켜기
        this.webCamSetup( newClass, index );


        // 예측하기 카메라 인식 쓰레드 정지
        this.stopPreviewRecongnition();
       
        // 예측하기 카메라를 끈다.
        if( this.state.previewTab.showWebCam ) {
            this.state.previewTab.showWebCam      = false;
            if( this.state.previewTab.webCam.myp5 ) {
                try{
                    this.state.previewTab.webCam.myp5.init();
                    this.state.previewTab.webCam.myp5.remove();
                    this.state.previewTab.webCam.myp5   = null;
                } catch( e ) {
                }
                try{
                    this.state.previewTab.webCam.canvas.remove();
                    this.state.previewTab.webCam.canvas = null;
                } catch( e ) {
                }
            }

            this.state.trainingTab.trainingStatus = "INIT";
            this.state.previewTab.previewStatus   = "INIT";
        }

        this.state.previewResults   = [];
        this.setState({
            class: newClass,
            trainingTab: this.state.trainingTab,
            previewTab:  this.state.previewTab,
            previewResults: []
        });
    }


    handleCloseWebCam ( index ) {
        this.handleHidePopup();
        let newClass = [...this.state.class];
        if( newClass[ index ].showWebCam ) {
            // 카메라 켜기
            this.webCamSetup( newClass, index );
        }
        this.setState({
            class: newClass
        });        
    }

    handleImageUploadHideShow (index) {
        this.handleHidePopup();
        
        let newClass = [...this.state.class];
        let prev = newClass[ index ].showFileUpload;
        for( let i = 0; i < newClass.length; i++ ) {
            if ( newClass[ i ].showWebCam ) {
                this.webCamSetup( newClass, i );
            }
            newClass[ i ].showFileUpload = false;
        }
        newClass[ index ].showFileUpload = !prev;

        this.setState({
            class: newClass
        });
    }

    handleClassNameChange (e, index) {
        let newClass = [...this.state.class];
        newClass[index].className = e.target.value;
        this.setState({
            class: newClass
        });
    }

    handleBtnEditName (index) {
        this.handleHidePopup();
        let newClass = [...this.state.class];
        newClass[index].isEditing = newClass[index].isEditing?false:true;
        this.setState({
            class: newClass
        });
    }

    handleKeydownEnter (e) {
        e.persist();    // 이것을 넣어줘야, render의 html에서 key를 부여하여, onchange가 변화되더라도, 키가 잘 입력된다.
        if ( e.key == 'Enter' ) {
            let index = 0;
            for( let i = 0; i < this.state.class.length; i++ ) {
                if( this.state.class[ i ].isEditing == true ) {
                    index = i;
                }
            }
            let isDuplicate = false;
            const className = this.state.class[ index ].className;
            for( let i = 0; i < this.state.class.length; i++ ) {
                if( index != i ) {
                    let clazzName = this.state.class[ i ].className;
                    if( className == clazzName ) {
                        isDuplicate   = true;
                        break;
                    } 
                }
            }

            if( !isDuplicate ) {
                let newClass = [...this.state.class];
                newClass.forEach( e => { e.isEditing = false })
                this.setState({
                    class: newClass
                });
            }
        }
    }

    handleUploadImages (e) {
    }
    
    handleResetPage (e) {
        // let isDirty = false;
        // 각 클래스별로, 촬영한 또는 업로드된 이미지가 있는지 검사.
        // for( let i = 0; i < this.state.class.length; i++ ) {
        //     const clazz = this.state.class[ i ];
        //     if( clazz.webCam.images.length > 0 ) {
        //         isDirty = true;
        //     }
        // }
        
        let result = confirm( this.props.intl.formatMessage(messages.msg3) );
        if( result ) {
            this.stopPreviewRecongnition();

            for( let i = 0; i < this.state.class.length; i++ ) {
                const clazz = this.state.class[ i ];
                if( clazz.webCam.canvas ) {
                    clazz.webCam.canvas.remove();
                }
                if ( clazz.webCam.myp5 ) {
                    clazz.webCam.myp5.remove();
                }
            }

            const pt = this.state.previewTab;
            pt.showWebCam   = false;
            if( pt.webCam.canvas ) {
                pt.webCam.canvas.remove();
                pt.webCam.canvas = null;
            }
            if( pt.webCam.myp5 ) {
                pt.webCam.myp5.init();
                pt.webCam.myp5.remove();
                pt.webCam.myp5 = null;
            }
            this.state = this.createMLData();
            this.state.trainingTab.currentEpoch   = 0; 
            this.state.trainingTab.totalEpoch     = this.state.trainingTab.epochs;             
            this.state.trainingTab.graphItems     = [{ epoch: 0,  value: 100}];
            this.state.trainingTab.loss           = "0.0";
            this.setState({
                class: this.state.class,
                trainingTab: this.state.trainingTab,
                previewTab: this.state.previewTab,
                previewResults: this.state.previewResults
            });
        }
    }

    handleBtnAdvancedCollapseClick () {
        let trainingTabNew = {...this.state.trainingTab};
        trainingTabNew.isAdvancedCollapse = trainingTabNew.isAdvancedCollapse?false:true;
        this.setState({
            trainingTab: trainingTabNew
        });
    }

    handleEpoch (e) {
        let trainingTabNew = {...this.state.trainingTab};
        trainingTabNew.epochs = e.target.value;
        this.setState({
            trainingTab: trainingTabNew
        });
    }
    handleBatchSize (e) {
        let trainingTabNew = {...this.state.trainingTab};
        trainingTabNew.batchSize = parseInt( e.target.value );
        this.setState({
            trainingTab: trainingTabNew
        });
    }    
    handleLearningRate (e) {
        let trainingTabNew = {...this.state.trainingTab};
        trainingTabNew.learningRate = e.target.value;
        this.setState({
            trainingTab: trainingTabNew
        });
    }   
    handleResetDefaults (e) {
        const mldata    = this.createMLData();
        mldata.trainingTab.isAdvancedCollapse = true;
        this.setState({
            trainingTab: mldata.trainingTab
        });
    }    

    handleApplyModel () {
        const extentionObject = this._getExtention();
        if( extentionObject ) {
            // 학습하기가 완료될때만, 모델을 적용한다.
            if( this.state.trainingTab.trainingStatus == 'TRAINED' ) {
                this.stopPreviewRecongnition();
                this.handlePreviewWebCam( "off" );
                this.state.previewTab.previewStatus = "APPLIED";
                extentionObject.applyModel().then( () => {
                    this.setState({
                        previewTab : this.state.previewTab
                    });       
                });
                this.props.onActivateTab( BLOCKS_TAB_INDEX );  // 코드탭
            }
        }
    }

    _getExtention() {
        let extentionId_mlaudio = this.props.vm.extensionManager._loadedExtensions.get( "mlaudio" );
        if( extentionId_mlaudio !== undefined ) {
            let extentionObject   = this.props.vm.extensionManager.getServices()[ extentionId_mlaudio ];
            if( extentionObject !== null && extentionObject !== undefined ) {
                return extentionObject;
            }
        }
        alert( this.props.intl.formatMessage(messages.msg4) );
        return null;
    }
    
    /**
     * 
     * @param {string} onOff    "on", "off"
     */
    handlePreviewWebCam( onOff ) {
        const   previewTab = this.state.previewTab;

        if( onOff == "on" ) {
            if (!previewTab.showWebCam) {
                let previewSpectogramArea = document.getElementById( 'previewSpectogramArea' );

                new p5( p => {
                    let canvas;
                    let offset = 0;
                    let _spectrumData = [];
                    const BASE_Y    = 60;
                    const H_PIXEL   = 60;
                    const W_PIXEL   = 66;
                    const TOTAL_SEC = 2;
                    const UNIT      = 6.6;
                    const COUNT     = 6;

                    const D_100ms   =  4403;        // 0.1초
                    const D_2s      = 88064;        // 2초 데이터

                    p.setup = function() {
                        p.noLoop();
                        canvas	= p.createCanvas( TOTAL_SEC * W_PIXEL, TOTAL_SEC * H_PIXEL );
                        canvas.style( 'border-radius', '5px' );
                        p.background('rgba(77,151,255,.5)');   
                        p.noStroke();       // 
                        p.fill(224, 224, 224);      // 회색
                        p.rect(0, BASE_Y, canvas.width, 1 );     // 기본 그리드 가로선, 1은 선 두깨     

                        previewTab.webCam.canvas    = canvas;
                        previewTab.webCam.myp5      = p;                        
                    };
                    p.draw = function() {
                        // p.image(img, 0, 0);
                        // waveform data : 176128
                        // 176128 : 2 =  : 0.1

                        // 2x = 17612.8
                        //  x =  8806.4 / 6
                        //  x =  1467.0 

                        if( _spectrumData.length != 0 ) {
                            p.noStroke();
                            p.fill(224, 224, 224);      // 회색
                            p.rect(0, BASE_Y, canvas.width, 1 );     // 기본 그리드 가로선, 1은 선 두깨
    
                            for (let i = 0; i < COUNT; i++){
                                let x = offset + i;
                                let y = p.map( _spectrumData[ parseInt( ( D_2s - D_100ms ) + D_100ms / UNIT * i ) ], -0.1, 0.1, -W_PIXEL, H_PIXEL );
                                
                                p.fill(3, 78, 161);                  // 엷은 파랑색
                                p.rect(x, BASE_Y, 1, y );            // 진폭 막대 그래프 보여주기
                                p.rect(x, BASE_Y, 1, -1 * y );       // 진폭 막대 그래프 반대 진폭 보여주기
        
                                p.fill(3, 78, 161);             // 엷은 파랑색
                                p.rect(x, BASE_Y, 1, 1 );       // 현재 녹음 지점 그려주기, 2는 두깨
                                // console.info(`${x}, 60, 1, ${y}, ${_spectrumData[ parseInt( ( 176128 - 8806 ) + 8806 / UNIT * i ) ]}, ${ parseInt( (176128 - 8806 ) + 8806 / UNIT * i ) }, ${_spectrumData.length}` );
                            }
                            offset 	+= UNIT;
                        }
                    };
                    p.setSpectrumData = function(spectrumData) {
                        _spectrumData = spectrumData;
                    },
                    p.init = function() {
                        p.clear();          // 모두 지우기
                        p.background('rgba(77,151,255,.5)');
                        p.noStroke();       // 
                        p.fill(224, 224, 224);      // 회색
                        p.rect(0, BASE_Y, canvas.width, 1 );     // 기본 그리드 가로선, 1은 선 두깨          
    
                        offset = 0;
                    }       
                }, 
                previewSpectogramArea
                );
                previewTab.showWebCam  = true;
            }
        } else {
            if ( previewTab.showWebCam) {
                if( previewTab.webCam.myp5 ) {
                    previewTab.webCam.myp5.init();
                    previewTab.webCam.myp5.remove();
                    previewTab.webCam.myp5 = null;
                    previewTab.webCam.canvas.remove();
                    previewTab.webCam.canvas = null;
                }
                previewTab.showWebCam   = false;
            }
        }
        this.setState({
            previewTab: previewTab
        });

    }

    startPreviewRecongnition() {
        const newPreviewTab = this.state.previewTab;

        // 마이크 UI를 켠다.
        newPreviewTab.micOn       = true;
        if( newPreviewTab.webCam ) {
            if( !newPreviewTab.showWebCam ) {
                this.handlePreviewWebCam( "on" );
            }
        } else {
            return;
        }

        const _processPreviewInternalPointer = this._processPreviewInternal;

        this.stopPreviewRecongnition();
        let count = 0;
        // 마이크 레코딩 시작 전, 레코딩을 시작한다.
        // 100ms 마다 실행되고, count를 세서, 10개 마다 1초로 간주하고, 버퍼를 저장한다.
        const ext = this._getExtention();
        ext.addMicDataListener( function( audioData ) {
            count += 1;
            if( count % 20 == 0 ) {
                // 2초 후, classify 하기
                if( newPreviewTab.webCam.myp5 != null ) {
                    const previewData = audioData.spectrogram.bufferSync().values;   // vals = Float32Array(9976), 1초
                    ext.classify( previewData, _processPreviewInternalPointer );
                    newPreviewTab.webCam.myp5.init();
                    newPreviewTab.webCam.myp5.redraw();
                }                
            } else {
                // 100ms 마다, 그래프 그려주기
                if( newPreviewTab.webCam.myp5 != null ) {
                    newPreviewTab.webCam.myp5.setSpectrumData( audioData.waveform.bufferSync().values );
                    newPreviewTab.webCam.myp5.redraw();
                }
            }
        });

        // this._audioRecordIntervalId =   setInterval( function() {
        //     count += 1;
        //     if( count % 20 == 0 ) {
        //         // 1초 마다 호출 됨
        //         ext.getCaptureAudioData().then( ( audioData ) => {
        //             if( newPreviewTab.webCam.myp5 != null ) {
        //                 const previewData = audioData.spectrogram.bufferSync().values;   // vals = Float32Array(9976), 1초
        //                 ext.classify( previewData, _processPreviewInternalPointer );
        //                 newPreviewTab.webCam.myp5.init();
        //                 newPreviewTab.webCam.myp5.redraw();
        //             }
        //         });
        //     } else {
        //         ext.getCaptureAudioData().then( ( audioData ) => {
        //             if( newPreviewTab.webCam.myp5 != null ) {
        //                 newPreviewTab.webCam.myp5.setSpectrumData( audioData.waveform.bufferSync().values );
        //                 newPreviewTab.webCam.myp5.redraw();
        //             }
        //         });                
        //     }
        //     // 0.1 초마다 호출, 그래프를 그려준다.
        // }, 100 );
    }

    _processPreviewInternal(error, result) {
        // console.info( error + ", " + result );
        // results
        // * 0: {blue-ish: 0.5556634664535522, label: "blue-ish", confidence: 0.5556634664535522}
        // * 1: {red-ish: 0.444336473941803, label: "red-ish", confidence: 0.444336473941803}

        let results = [];
        result.map( item => {
            results.push( {
                className: item.label,
                value: Math.round( item.confidence * 100 )
            } );
            console.info( "label=" + item.label + ", confidence=" + item.confidence );
        });
        console.info( "" );
        this.state.previewResults = results;
        this.setState({
            previewResults : this.state.previewResults
        });                        
    }

    stopPreviewRecongnition() {
        clearInterval( this._audioRecordIntervalId );
        this._audioRecordIntervalId = null;
    }


    handleDeleteImageSample( classIndex, itemId ) {
        let newClass  = [...this.state.class];
        
        let spectrumItems = newClass[ classIndex ].webCam.spectrumItems;
        spectrumItems = spectrumItems.filter(
            item => ( item.id != itemId )
            );
        newClass[ classIndex ].webCam.spectrumItems = spectrumItems;

        let waveformItems = newClass[ classIndex ].webCam.waveformItems;
        waveformItems = waveformItems.filter(
            item => ( item.id != itemId )
            );
        newClass[ classIndex ].webCam.waveformItems = waveformItems;

        this.setState({
            class: newClass
        })
    }

    handleBtnTermHelp(num) {
        let prevStatus  = false;
        if( num == 0 ) {
            prevStatus  = this.state.trainingTab.showOptionPopupEpoch;
            this.state.trainingTab.showOptionPopupEpoch         = true;
            this.state.trainingTab.showOptionPopupBatchSize     = false;
            this.state.trainingTab.showOptionPopupLearning      = false;

            this.state.trainingTab.showOptionPopupEpoch         = !prevStatus;
        } else if ( num == 1)  {
            prevStatus  = this.state.trainingTab.showOptionPopupBatchSize;
            this.state.trainingTab.showOptionPopupEpoch         = false;
            this.state.trainingTab.showOptionPopupBatchSize     = true;
            this.state.trainingTab.showOptionPopupLearning      = false;            

            this.state.trainingTab.showOptionPopupBatchSize     = !prevStatus;
        } else if ( num == 2 ) {
            prevStatus  = this.state.trainingTab.showOptionPopupLearning;
            this.state.trainingTab.showOptionPopupEpoch         = false;
            this.state.trainingTab.showOptionPopupBatchSize     = false;
            this.state.trainingTab.showOptionPopupLearning      = true; 

            this.state.trainingTab.showOptionPopupLearning      = !prevStatus;
        } else {
        }
        this.setState({
            trainingTab: this.state.trainingTab
        });        
    }

    handleAddedPreviewUploadImage(imageData) {
        this.state.previewTab.webCam.image = imageData;
        this.setState({
            previewTab: this.state.previewTab
        });

        const s = (p) => {
            p.preload = () => {
                let img  = this.state.previewTab.webCam.image;
                let _img = p.createImage(100, 100);
                _img.loadPixels();
                p.createCanvas( 100, 100 );
                for( let i=0; i < img.length; i++ ) {
                    _img.pixels[ i ] = img[ i ];
                }
                _img.updatePixels();
                p.image( _img, 0, 0, 100, 100 );
                _img.loadPixels();

                let previewData = _img.pixels;

                const extentionObject = this._getExtention();
                if( extentionObject ) {
                    extentionObject.classify( previewData, this._processPreviewInternal );
                }                         
            }
            // p.draw = () => {
                // }
            };
        let t = new p5(s);
        t.remove();        
    }

    // /**
    //  * 예측하기에서, 이미지가 업로드 되었을 때, 분류를 한다.
    //  */
    // handlePreviewPostUploadedImage() {
    //     const s = (p) => {
    //         p.preload = () => {
    //             let img  = this.state.previewTab.webCam.image;
    //             let _img = p.createImage(100, 100);
    //             _img.loadPixels();
    //             p.createCanvas( 100, 100 );
    //             for( let i=0; i < img.length; i++ ) {
    //                 _img.pixels[ i ] = img[ i ];
    //             }
    //             _img.updatePixels();
    //             p.image( _img, 0, 0, 100, 100 );
    //             _img.loadPixels();

    //             let previewData = _img.pixels;

    //             const extentionObject = this._getExtention();
    //             if( extentionObject ) {
    //                 extentionObject.classify( previewData, this._processPreviewInternal );
    //             }                         
    //         }
    //         // p.draw = () => {
    //             // }
    //         };
    //     let t = new p5(s);
    //     t.remove();
    // }

    handleAddedClassUploadImage() {
        
    }

    handleChangedMicOnOff( event ) {
        if( this.state.trainingTab.trainingStatus == "TRAINED" ) {
            // 마이크 상태 바꾸기 ( true or false )
            this.state.previewTab.micOn = !this.state.previewTab.micOn;

            if( this.state.previewTab.micOn ) {
                this.startPreviewRecongnition();
            } else {
                const ext   = this._getExtention();
                ext.removeMicDataListener();
            }
            this.setState({
                previewTab: this.state.previewTab
            });
        }
    }

    render () {
        return (
            <MLAudioEditorComponent
                onOpen={this.handleOpen}
                onSave={this.handleSave}
                saveFile={this.handleSaveFile}
                onEdit={this.handleEdit}
                onShowWebCam={this.handleShowWebCam}
                onAddClass={this.handleAddClass}
                onBtnOptionClicked={this.handleClassOptionClick}
                onDeleteClass={this.handleDeleteClass}
                onDisableClass={this.handleDisableClass}
                onRemoveAllSample={this.handleRemoveAllSample}
                onDownloadSample={this.handleDownloadSample}
                onHidePopup={this.handleHidePopup}
                onCloseWebCam={this.handleCloseWebCam}
                onBtnImageUploadClicked={this.handleImageUploadHideShow}
                onCloseFileUpload={this.handleImageUploadHideShow}
                onClassNameChange={this.handleClassNameChange}
                onBtnEditNameClicked={this.handleBtnEditName}
                onEditNameDone={this.handleBtnEditName}
                onKeyEnterPress={this.handleKeydownEnter}
                onUploadImage={this.handleUploadImages}
                onBtnNewClicked={this.handleResetPage}
                onBtnAdvancedCollapseClick={this.handleBtnAdvancedCollapseClick}
                onRecordAudio={this.handleAudioRecord}
                onApplyRecordAudio={this.handleApplyAudioRecord}
                
                onSampleImage={this.handleSampleImage}
                onClickTrain={this.handleTrain}

                onChangedEpoch={this.handleEpoch}
                onChangedBatchSize={this.handleBatchSize}
                onChangedLearningRate={this.handleLearningRate}
                onBtnResetDefaults={this.handleResetDefaults}

                onBtnApplyModel={this.handleApplyModel}
                onPreviewWebCam={this.handlePreviewWebCam}

                class={this.state.class}
                trainingTab={this.state.trainingTab}
                previewTab={this.state.previewTab}
                previewResults={this.state.previewResults}

                onDeleteSampleImage={this.handleDeleteImageSample}

                onAddedClassUploadImage={this.handleAddedClassUploadImage}
                onAddedPreviewUploadImage={this.handleAddedPreviewUploadImage}
                // onPreviewPostUploadedImage={this.handlePreviewPostUploadedImage}
                onBtnTermHelp={this.handleBtnTermHelp}

                onChangedMicOnOff={this.handleChangedMicOnOff}
            />
        );
    }
}

MLAudioEditor.propTypes = {
    isRtl: PropTypes.bool,
};

const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}.mla`;
};

const mapStateToProps = state => {
    return { 
        isRtl: state.locales.isRtl,
        projectFilename: getProjectFilename(state.scratchGui.projectTitle, projectTitleInitialState)
    }
};

const mapDispatchToProps = dispatch => ({
    onActivateTab: tabIndex => {
        dispatch(activateTab(tabIndex));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MLAudioEditor);