import React from 'react';

import cStyles from './css/common.css';

import * as p5 from "p5";
import { element } from 'prop-types';

class PreviewImageComponent extends React.Component {

    constructor (props) {
        super(props);
        this.setDivRef = element => {
            this.divRef = element;
        }
        // this.onPostUploadedImage.bind(this);
    }

    componentDidMount() {
        const s = (p) => {
            p.setup = () => {
                p.noLoop();
                p.createCanvas( 200, 200 );
            }
        };
        this.myP5 = new p5(s, this.divRef);
    }

    componentDidUpdate(preProps) {
        this.myP5.remove();
        this.myP5   = null;
        const s = (p) => {
            p.setup = () => {
                p.noLoop();
                p.createCanvas( 200, 200 );
            }
            p.draw = () => {
                let img     = this.props.uploadImage;
                if( img != null ) {
                    let _img = p.createImage(100, 100);
                    _img.loadPixels();
                    for( let i=0; i < img.length; i++ ) {
                        _img.pixels[ i ] = img[ i ];
                    }
                    _img.updatePixels();
                    p.image( _img, 0, 0, 200, 200 );
                }
            }
        };
        this.myP5 = new p5(s, this.divRef);
    }

    render() {
        return (
            <div className={cStyles.result} ref={this.setDivRef} >
            </div>
        );
    }
}

export default PreviewImageComponent;