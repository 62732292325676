import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';

import Box from '../box/box.jsx';
import Dots from './dots.jsx';
import helpIcon from './icons/help.svg';
import backIcon from './icons/back.svg';
import bluetoothIcon from './icons/bluetooth.svg';
import bitbrickBoardIcon from './icons/bitBrick_Mainboard_icon.svg';
import diaboardBoardIcon from './icons/diaboard_Mainboard_icon.svg';
import coalaBoardIcon from './icons/diaboard_Mainboard_icon.svg';
import scratchLinkIcon from './icons/scratchlink.svg';
import bitbrickLinkIcon from './icons/bitbricklink.svg';

import styles from './connection-modal.css';

import bitbrickGuide from '../bitbrick/help/[도움말] 비트브릭 스크래치 3.0.pdf';

const UnavailableStep = props => (

    <Box className={styles.body}>
        <Box className={styles.activityArea}>
            <div className={styles.scratchLinkHelp}>
                <div className={styles.scratchLinkHelpStep}>
                    <div className={styles.helpStepNumber}>
                        {'1'}
                    </div>
                    <div className={styles.helpStepImage}>

                    { ( props.extensionId == "bitbrick"
                            || props.extensionId == "diaboard"
                            || props.extensionId == "coalaboard" ) ? (
                        <img
                            className={styles.scratchLinkIcon}
                            src={bitbrickLinkIcon}
                        />
                    ) : (
                        <img
                            className={styles.scratchLinkIcon}
                            src={scratchLinkIcon}
                        />
                    )}
                    </div>
                    <div className={styles.helpStepText}>
                    { ( props.extensionId == "bitbrick"
                            || props.extensionId == "diaboard"
                            || props.extensionId == "coalaboard" ) ? (
                        <FormattedMessage
                            defaultMessage="Make sure you have Scratch Link installed and running"
                            description="Message for getting Scratch Link installed"
                            id="gui.connection.unavailable.installscratchlink_bitbrick"
                        />
                    ) : (
                        <FormattedMessage
                            defaultMessage="Make sure you have Scratch Link installed and running"
                            description="Message for getting Scratch Link installed"
                            id="gui.connection.unavailable.installscratchlink"
                        />
                    )}
                </div>
                </div>
                <div className={styles.scratchLinkHelpStep}>
                    <div className={styles.helpStepNumber}>
                        {'2'}
                    </div>
                    <div className={styles.helpStepImage}>
                    { props.extensionId == "bitbrick" ? (
                        <img
                            className={styles.scratchLinkIcon}
                            src={bitbrickBoardIcon}
                        />
                    ) : (
                        props.extensionId == "diaboard" ? (
                            <img
                                className={styles.scratchLinkIcon}
                                src={diaboardBoardIcon}
                            />
                        ) : (
                            props.extensionId == "coalaboard" ? (
                                <img
                                    className={styles.scratchLinkIcon}
                                    src={coalaBoardIcon}
                                />
                            ) : (                    
                                <img
                                    className={styles.scratchLinkIcon}
                                    src={bluetoothIcon}
                                />
                            )
                        )
                    )}
                    </div>
                    <div className={styles.helpStepText}>
                    { ( props.extensionId == "bitbrick"
                            || props.extensionId == "diaboard"
                            || props.extensionId == "coalaboard" ) ? (
                        <FormattedMessage
                            defaultMessage="Check that Bluetooth is enabled"
                            description="Message for making sure Bluetooth is enabled"
                            id="gui.connection.unavailable.enablebluetooth_bitbrick"
                        />
                    ) : (
                        <FormattedMessage
                            defaultMessage="Check that Bluetooth is enabled"
                            description="Message for making sure Bluetooth is enabled"
                            id="gui.connection.unavailable.enablebluetooth"
                        />
                    )}
                    </div>
                </div>
            </div>
        </Box>
        <Box className={styles.bottomArea}>
            <Dots
                error
                className={styles.bottomAreaItem}
                total={3}
            />
            <Box className={classNames(styles.bottomAreaItem, styles.buttonRow)}>
                <button
                    className={styles.connectionButton}
                    onClick={props.onScanning}
                >
                    <img
                        className={classNames(styles.buttonIconLeft, styles.buttonIconBack)}
                        src={backIcon}
                    />
                    <FormattedMessage
                        defaultMessage="Try again"
                        description="Button to initiate trying the device connection again after an error"
                        id="gui.connection.unavailable.tryagainbutton"
                    />
                </button>
                { props.extensionId == "bitbrick" ? (
                <a href={bitbrickGuide} target="_blank" rel="noopener noreferrer" download="[도움말] 비트브릭 스크래치 3.0.pdf" style={{textDecoration: 'none', color: 'inherit'}}>
                <button
                    className={styles.connectionButton}
                    // onClick={props.onHelp}
                >
                    <img
                        className={styles.buttonIconLeft}
                        src={helpIcon}
                    />
                    <FormattedMessage
                        defaultMessage="Help"
                        description="Button to view help content"
                        id="gui.connection.unavailable.helpbutton"
                    />
                </button>
                </a>
                ) : ( 
                    props.extensionId == "diaboard" ? (
                        <a href='http://hellogeeks.cafe24.com/education_diaboard' target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
                        <button
                            className={styles.connectionButton}
                            // onClick={props.onHelp}
                            >
                            <img
                                className={styles.buttonIconLeft}
                                src={helpIcon}
                            />
                            <FormattedMessage
                                defaultMessage="Help"
                                description="Button to view help content"
                                id="gui.connection.unavailable.helpbutton"
                            />
                        </button>
                        </a>
                    ) : (
                        props.extensionId == "coalaboard" ? (
                            <a href='http://hellogeeks.cafe24.com/education_diaboard' target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'inherit'}}>
                            <button
                                className={styles.connectionButton}
                                // onClick={props.onHelp}
                                >
                                <img
                                    className={styles.buttonIconLeft}
                                    src={helpIcon}
                                />
                                <FormattedMessage
                                    defaultMessage="Help"
                                    description="Button to view help content"
                                    id="gui.connection.unavailable.helpbutton"
                                />
                            </button>
                            </a>
                        ) : (
                            <button
                                className={styles.connectionButton}
                                onClick={props.onHelp}
                                >
                                <img
                                    className={styles.buttonIconLeft}
                                    src={helpIcon}
                                />
                                <FormattedMessage
                                    defaultMessage="Help"
                                    description="Button to view help content"
                                    id="gui.connection.unavailable.helpbutton"
                                />
                            </button>
                        )
                    )
                )}
            </Box>
        </Box>
    </Box>
);

UnavailableStep.propTypes = {
    onHelp: PropTypes.func,
    onScanning: PropTypes.func
};

export default UnavailableStep;
