import React from 'react';

import cStyles from './css/common.css';

import * as p5 from "p5";
import { element } from 'prop-types';

class SampleAudioComponent extends React.Component {

    constructor (props) {
        super(props);
        this.setDivRef = element => {
            this.divRef = element;
        }
    }

    componentDidMount() {
        const s = (p) => {
            const BASE_Y    = 30;
            const H_PIXEL   = 60;
            const W_PIXEL   = 60;
            const TOTAL_SEC = 1;
            const UNIT      = 60;
            const COUNT     = 60;
            p.setup = () => {
                let _spectrumData     = this.props.item.data;
                let length            = _spectrumData.length;
                let canvas	= p.createCanvas( TOTAL_SEC * W_PIXEL, TOTAL_SEC * H_PIXEL );
                canvas.style( 'border-radius', '5px' );
                p.background('rgba(77,151,255,.5)');

                p.noStroke();
                p.fill(3, 78, 161);      // 회색
                p.rect(0, BASE_Y, canvas.width, 1 );     // 기본 그리드 가로선, 1은 선 두깨
            
                for (let i = 0; i < COUNT; i++){
                    let x = i;
                    let y = p.map( _spectrumData[ parseInt( length / UNIT * i ) ], -0.1, 0.1, -W_PIXEL, H_PIXEL );
                    p.fill(3, 78, 161);                  // 엷은 파랑색
                    p.rect(x, BASE_Y, 1, y );            // 진폭 막대 그래프 보여주기
                    p.rect(x, BASE_Y, 1, -1 * y );       // 진폭 막대 그래프 반대 진폭 보여주기
                }
            }
        };
        this.myP5 = new p5(s, this.divRef);
    }
    
    render() {
        return (
            <div className={cStyles.wrap_img} ref={this.setDivRef} >
                <button type="button" 
                    className={cStyles.btn_del} 
                    onClick={() => {this.props.onDeleteImageSample(this.props.classIndex, this.props.item.id)}}>
                </button>
            </div>
        );
    }
}

export default SampleAudioComponent;