import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
// import React, { Component } from 'react';
import {
    CartesianGrid, Line, LineChart, ResponsiveContainer,
    Tooltip, XAxis, YAxis
} from 'recharts';
import MLAudioDownloader from '../../containers/mlaudio-file-downloader.jsx';
import MLAudioFileUploader from '../../containers/mlaudio-file-uploader.jsx';
import cStyles from './css/common.css';
import SampleAudioComponent from './sample-audio.jsx';



// import { scalePow, scaleLog } from 'd3-scale';
// import CustomLineDot from './CustomLineDot';
// import { changeNumberOfData } from './utils';

const messages = defineMessages({
    open: {
        id: 'gui.mlaudio.open',
        description: '',
        defaultMessage: 'Open'
    },
    save: {
        id: 'gui.mlaudio.save',
        description: '',
        defaultMessage: 'Save'
    },
    new: {
        id: 'gui.mlaudio.new',
        description: '',
        defaultMessage: 'New'
    },
    edit: {
        id: 'gui.mlaudio.edit',
        description: '',
        defaultMessage: 'Edit'
    },
    camera: {
        id: 'gui.mlaudio.camera',
        description: '',
        defaultMessage: 'Camera'
    },
    upload: {
        id: 'gui.mlaudio.upload',
        description: '',
        defaultMessage: 'Upload'
    },
    addclass: {
        id: 'gui.mlaudio.addclass',
        description: '',
        defaultMessage: 'Add Class'
    },
    training: {
        id: 'gui.mlaudio.training',
        description: '',
        defaultMessage: 'Training'
    },
    trainmodel: {
        id: 'gui.mlaudio.trainmodel',
        description: '',
        defaultMessage: 'Train Model'
    },    
    advanced: {
        id: 'gui.mlaudio.advanced',
        description: '',
        defaultMessage: 'Advanced'
    },
    epochDesc: {
        id: 'gui.mlaudio.epochDesc',
        description: '',
        defaultMessage: "Epocs, One epoch means that each and every sample in the training dataset has been fed through the training model at least once. If your epochs are set to 50, for example, it means that the model you are training will work through the entire training dataset 50 times. Generally the larger the number, the better your model will learn to predict the data. You probably want to tweak (usually increase) this number until you get good predictive results with your model.",
    },
    batchsizeDesc: {
        id: 'gui.mlaudio.batchsizeDesc',
        description: '',
        defaultMessage: "Batch Size, A batch is a set of samples used in one iteration of training. For example, let's say that you have 80 images and you choose a batch size of 16. This means the data will be split into 80 / 16 = 5 batches. Once all 5 batches have been fed through the model, exactly one epoch will be complete. You probably won't need to tweak this number to get good training results.",
    },
    learningrateDesc: {
        id: 'gui.mlaudio.learningrateDesc',
        description: '',
        defaultMessage: "Learning Rate, Be careful tweaking this number! Even small differences can have huge effects on how well your model learns.",
    },    
    preview: {
        id: 'gui.mlaudio.preview',
        description: '',
        defaultMessage: 'Preview'
    },
    preview: {
        id: 'gui.mlaudio.preview',
        description: '',
        defaultMessage: 'Preview'
    },
    exportmodel: {
        id: 'gui.mlaudio.applymodel',
        description: '',
        defaultMessage: 'Apply the model'
    },
    appliedmodel: {
        id: 'gui.mlaudio.appliedmodel',
        description: '',
        defaultMessage: 'Applied the model'
    },
    audioDesc: {
        id: 'gui.mlaudio.audioDesc',
        description: '',
        defaultMessage: 'Add Audio Sample(3~5)'
    },
    record: {
        id: 'gui.mlaudio.record',
        description: '',
        defaultMessage: 'Record'
    },
    status_record: {
        id: 'gui.mlaudio.status_record',
        description: '',
        defaultMessage: 'Record 2 Seconds'
    },
    status_recording: {
        id: 'gui.mlaudio.status_recording',
        description: '',
        defaultMessage: 'Recording'
    },
    status_recorded: {
        id: 'gui.mlaudio.status_recorded',
        description: '',
        defaultMessage: 'Recorded'
    },
    status_stop: {
        id: 'gui.mlaudio.status_stop',
        description: '',
        defaultMessage: 'Stop'
    },
    add: {
        id: 'gui.mlaudio.add',
        description: '',
        defaultMessage: 'Add'        
    },
    audiosamples: {
        id: 'gui.mlaudio.audiosamples',
        description: '',
        defaultMessage: 'Audio Samples'
    },    
    deleteclass: {
        id: 'gui.mlaudio.deleteclass',
        description: '',
        defaultMessage: 'Delete Class'
    },
    removeallsamples: {
        id: 'gui.mlaudio.removeallsamples',
        description: '',
        defaultMessage: 'Remove All Samples'
    },
    removeallsamples: {
        id: 'gui.mlaudio.removeallsamples',
        description: '',
        defaultMessage: 'Remove All Samples'
    },
    model_preparing: {
        id: 'gui.mlaudio.model.preparing',
        description: '',
        defaultMessage: 'Preparing training data..'
    },
    model_training: {
        id: 'gui.mlaudio.model.training',
        description: '',
        defaultMessage: 'Training..'
    },
    model_trained: {
        id: 'gui.mlaudio.model.trained',
        description: '',
        defaultMessage: 'Model Trained'
    },
    model_lossrate: {
        id: 'gui.mlaudio.model.lossrate',
        description: '',
        defaultMessage: 'Loss Rate'
    },
    resetdefaults: {
        id: 'gui.mlaudio.resetdefaults',
        description: '',
        defaultMessage: 'Reset Defaults'
    },
    input: {
        id: 'gui.mlaudio.input',
        description: '',
        defaultMessage: 'Input'
    },
    mic: {
        id: 'gui.mlaudio.mic',
        description: '',
        defaultMessage: 'Mic'
    }
});

function handleClassRecordStatus( props, status ) {
    let sec = "0";
    if ( status == "INIT" ) {
        return props.intl.formatMessage(messages.status_record);        // "녹음하기"
    } else if ( status == "RECORDING" ) {
        return props.intl.formatMessage(messages.status_recording);     // "녹음 중"
    } else if ( status == "RECORDED" ) {
        return props.intl.formatMessage(messages.status_recorded);      // "녹음하기", 녹음 완료
    } else if ( status == "STOP" ) {
        return props.intl.formatMessage(messages.status_stop);          // "정지"
    } else {
        return "";
    }
}

function handleClassRecordStatusColor( status ) {
    if ( status == "INIT" ) {
        return cStyles.btn_record_status_init;
    } else if ( status == "RECORDING" ) {
        return cStyles.btn_record_status_progress;
    } else if ( status == "RECORDED" ) {
        return cStyles.btn_record_status_completion;
    } else if ( status == "STOP" ) {
        return cStyles.btn_record_status_completion;
    } else {
        return "";
    }
}

function handleTrainingStatus( props, status ) {
    if ( status == "PRE_TRAINING" ) {
        return props.intl.formatMessage(messages.model_preparing);      // "모델 학습 준비 중..."
    } else if ( status == "TRAINING" ) {
        return props.intl.formatMessage(messages.model_training);       // "모델 학습 중..."
    } else if ( status == "TRAINED" ) {
        return props.intl.formatMessage(messages.model_trained);        // "모델 학습 완료"
    } else {
        return props.intl.formatMessage(messages.trainmodel);           // "모델 학습하기"
    }
}

function handlePreviewStatus( props, status ) {
    if ( status == "APPLIED" ) {
        return props.intl.formatMessage(messages.appliedmodel);         // "학습모델이 적용되었습니다."
    } else {
        return props.intl.formatMessage(messages.exportmodel);          // "학습모델 블록에 적용하기"
    }
}

function handleTrainingStatusButtonColor( status ) {
    if ( status == "PRE_TRAINING" ) {
        return cStyles.training_status_progress;
    } else if ( status == "TRAINING" ) {
        return cStyles.training_status_progress;
    } else if ( status == "TRAINED" ) {
        return cStyles.training_status_completion;
    } else {
        return cStyles.training_status_init;
    }
}

function handlePreviewStatusColor( status ) {
    if ( status == "APPLIED" ) {
        return cStyles.preview_status_applied;
    } else {
        return cStyles.preview_status_init;
    }
}

function handlePreviewStatusIcon( status ) {
    if ( status == "APPLIED" ) {
        return cStyles.icn_apply_white;
    } else {
        return cStyles.icn_apply;
    }
}

// function handlePreviewCameraUploadUI( isVisible ) {
//     return ( isVisible ? cStyles.wrap_box_show : cStyles.wrap_box_hide );
// }

// function handlePreviewCameraUploadUI2( isVisible ) {
//     return ( isVisible ? 
//         { visibility: "visible", textAlign: "center" } :
//         { visibility: "hidden",  textAlign: "center", width: "0px", height: "0px", minWidth: "0px", minHeight: "0px", padding : "0px" }
//         );
// }

class XAxisTick extends PureComponent {
    render() {
        const {
        x, y, stroke, payload,
        } = this.props;

        return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={8} dx={4} textAnchor="end" fill="#666" fontSize="0.6em">{payload.value}</text>
        </g>
        );
    }
}

class YAxisTick extends PureComponent {
    render() {
        const {
        x, y, stroke, payload,
        } = this.props;

        return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={3} textAnchor="end" fill="#666" fontSize="0.6em">{payload.value.toFixed(0)}</text>
        </g>
        );
    }
}

const MLAudioEditor = props => (
    
<div className={cStyles.wrapper}>
    <div className={cStyles.header}>
        <div className={cStyles.header_wrap}>
            <button type="button" className={cStyles.btn_menu}><span className={[cStyles.icn_32, cStyles.icn_menu].join(" ")}>menu</span></button>
            <h1 className={cStyles.title}>AI Learning</h1>
        </div>
    </div>

    <div className={[cStyles.side_menu, cStyles.show].join(" ")}> {/* <!-- If want to show side menu, remove class "hide" --> */}
        <ul>
            {/* <li> */}
                {/* <button
                    type="button"
                    className={cStyles.btn_open}
                    title={props.intl.formatMessage(messages.open)}
                    onClick={props.onOpen}
                    >{props.intl.formatMessage(messages.open)}</button> */}
            {/* <MLImageFileUploader 은 내부적으로 li 태그를 사용 */}
            <MLAudioFileUploader
                canSave={true}
                userOwnsProject={true}
                onOpen={props.onOpen}
            >
                {(className, renderFileInput, handleLoadProject) => (
                    <li
                        onClick={handleLoadProject}>
                        {/* eslint-disable max-len */}
                        {/* eslint-enable max-len */}
                        {renderFileInput()}
                        <button className={cStyles.btn_open}>{props.intl.formatMessage(messages.open)}</button>
                    </li>
                )}
            </MLAudioFileUploader>
            {/* </li> */}
            {/* <li>
                <button
                    type="button"
                    className={cStyles.btn_save}
                    title={props.intl.formatMessage(messages.save)}
                    onClick={props.onSave}
                    >{props.intl.formatMessage(messages.save)}</button>
            </li> */}
            <MLAudioDownloader
                saveFile={props.saveFile}
            >
                {(className, downloadProjectCallback) => (
                <li
                    onClick={downloadProjectCallback}>
                    {/* eslint-disable max-len */}
                    {/* eslint-enable max-len */}
                    <button className={cStyles.btn_save}>{props.intl.formatMessage(messages.save)}</button>
                </li>
                )}
            </MLAudioDownloader>
            <li>
                <button
                    type="button"
                    className={cStyles.btn_new}
                    title={props.intl.formatMessage(messages.new)}
                    onClick={props.onBtnNewClicked}
                    >{props.intl.formatMessage(messages.new)}</button>
            </li>
        </ul>
    </div>
    <div className={cStyles.contents_wrap}>
        <div className={cStyles.contents}>
            <div className={cStyles.warp_box}>
                <div className={cStyles.box_left}>

                    {/* <!-- Box --> */}
                    
                        {
                            props.class.map( (i, index) => {
                                return(
                                    <div key={index} className={cStyles.box}>
                                        {/* <div className={cStyles.title_bar}>
                                            <h1 className={cStyles.title}><span>Class</span><button type="button" className={[cStyles.icn_20, cStyles.icn_edit].join(" ")}>edit</button></h1>
                                            <button type="button" className={cStyles.btn}><span className={[cStyles.icn_24, cStyles.icn_option].join(" ")}>option</span></button>
                                            
                                        </div> */}
                                        <div className={cStyles.title_bar}>
                                            <h1 className={cStyles.title}>
                                                {
                                                    i.isEditing?
                                                        <input
                                                            style={{background: "transparent", border: "none", color: "white"}}
                                                            value={i.className}
                                                            onChange={(e) => props.onClassNameChange(e, index)}
                                                            onBlur={() => {props.onEditNameDone(index)}}
                                                            onKeyDown={props.onKeyEnterPress}
                                                        />:
                                                        <span>{i.className}</span>
                                                }

                                                {
                                                    !i.isEditing?
                                                        <button
                                                            type="button"
                                                            className={[cStyles.icn_20, cStyles.icn_edit].join(" ")}
                                                            title={props.intl.formatMessage(messages.edit)}
                                                            onClick={() => {props.onBtnEditNameClicked(index)}}
                                                            >{props.intl.formatMessage(messages.edit)}
                                                        </button>:
                                                        null
                                                }
                                            </h1>
                                            <button type="button" className={cStyles.btn} onClick={() => props.onBtnOptionClicked(index)}><span className={[cStyles.icn_24, cStyles.icn_option].join(" ")}>option</span></button>
                                            {
                                                i.showOptionPopup?
                                                    <ul className={cStyles.pop_option} onBlur={props.onHidePopup}>
                                                        <li ><label onClick={() => props.onDeleteClass(index)}>{props.intl.formatMessage(messages.deleteclass)}</label></li>
                                                        {/* <li onClick={() => props.onDisableClass(index)}><label>Disable Class</label></li> */}
                                                        <li onClick={() => props.onRemoveAllSample(index)}><label>{props.intl.formatMessage(messages.removeallsamples)}</label></li>
                                                        {/* <li onClick={() => props.onDownloadSample(index)}><label>샘플 이미지 다운로드하기</label></li> */}
                                                    </ul>: null
                                            }
                                        </div>

                                        <div className={cStyles.box_cont}>
                                            <h2 className={[cStyles.sub_title, (i.showWebCam || i.showFileUpload)?cStyles.hide:''].join(" ")}>&nbsp;{/*Add Images Samples:*/}</h2>
                                            <div className={[cStyles.wrap_btn, (i.showWebCam || i.showFileUpload)?cStyles.hide:''].join(" ")}> {/* <!-- toggle hide show  --> */}
                                                <div className={[cStyles.wrap_btn_class, (i.showWebCam || i.showFileUpload)?cStyles.hide:''].join(" ")}> {/* <!-- toggle hide show  --> */}
                                                    <button type="button" onClick={() => {props.onShowWebCam(index)}} className={cStyles.btn}><span className={[cStyles.icn_24, cStyles.icn_mic].join(" ")}></span><span>{props.intl.formatMessage(messages.mic)}</span></button>
                                                    {/* <button type="button" onClick={() => {props.onBtnImageUploadClicked(index)}} className={cStyles.btn}><span className={[cStyles.icn_24, cStyles.icn_upload].join(" ")}></span><span>업로드</span></button> */}
                                                    {
                                                        ( i.showWebCam ) ? null : (
                                                            <div className={cStyles.wrap_image_upload}>
                                                                <div className={cStyles.list_img}> 
                                                                    {
                                                                        i.webCam.waveformItems.map( ( _item, _itemIndex ) => (
                                                                            <SampleAudioComponent
                                                                                key={_item.id}
                                                                                class={i} 
                                                                                item={_item}
                                                                                classIndex={index}
                                                                                itemIndex={_itemIndex}
                                                                                onDeleteImageSample={props.onDeleteSampleImage}
                                                                                >
                                                                            </SampleAudioComponent>
                                                                            )
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {/* WEBCAM */}
                                        <div className={[cStyles.box_cont, !i.showWebCam?cStyles.hide:''].join(" ")}>
                                            <h2 className={cStyles.sub_title}>{ ( i.webCam.images.length == 0 ) ? props.intl.formatMessage(messages.audioDesc) : i.webCam.images.length + ' ' + props.intl.formatMessage(messages.sample) }</h2>
                                            <div className={cStyles.wrap_selection}>
                                                <div className={cStyles.wrap_func_upload}> 
                                                    <h1 className={cStyles.title01}><span className={[cStyles.icn_32, cStyles.icn_mic_23].join(" ")}>mic</span><span>{props.intl.formatMessage(messages.mic)}</span></h1>
                                                    <button type="button" onClick={() => {props.onCloseWebCam(index)}} className={cStyles.btn_close}><span className={[cStyles.icn_24, cStyles.icn_close].join(" ")}></span></button>
                                                    <div className={cStyles.wrap_via_micphone}>
                                                        <div id={"spectogramArea_" + index} className={cStyles.spectogram}>
                                                        </div>
                                                        {/* <span className={[cStyles.icn_24, cStyles.icn_play].join(" ")}>play</span><span>00:00</span><span>/ 20:00</span>
                                                        <button type="button" className={cStyles.btn_primary} style={{marginLeft: "30px"}} onClick={() => {props.onRecordAudio(index)}}>적용하기</button> */}
                                                        { i.recordStatus == "RECORDED" || i.recordStatus == "STOP" ? 
                                                            (
                                                                <p className={cStyles.duration_cont}>
                                                                    <span className={[cStyles.icn_24].join(" ")}>play</span><span>00:00</span><span>/ 02:00</span>
                                                                    <button type="button" className={cStyles.btn_primary} style={{width: "132px", float: "right"}} onClick={() => {props.onApplyRecordAudio(index)}}><span className={[cStyles.icn_16, cStyles.icn_add].join(" ")}>add</span>&nbsp;<span>{props.intl.formatMessage(messages.add)}</span></button>
                                                                </p>
                                                            ) 
                                                            : null
                                                        }
                                                        <div className={cStyles.wrap_btn}>
                                                            <button type="button" className={[cStyles.btn_primary, cStyles.btn_record, handleClassRecordStatusColor(i.recordStatus)].join(" ")} onClick={() => {props.onRecordAudio(index)}}>{handleClassRecordStatus(props, i.recordStatus)}</button>
                                                            {/* <button type="button" className={cStyles.btn_setting}><span className={[cStyles.icn_24, cStyles.icn_setting].join(" ")}></span></button> */}
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                                <div className={cStyles.wrap_list_file}>
                                                    <h1 className={cStyles.title02}>{ ( i.webCam.waveformItems.length == 0 ) ? props.intl.formatMessage(messages.audioDesc) : i.webCam.waveformItems.length + ' ' + props.intl.formatMessage(messages.audiosamples) }</h1>
                                                    <div id={"img_items_" + index} className={cStyles.list_file}>
                                                        {
                                                            i.webCam.waveformItems.map( ( _item, _itemIndex ) => (
                                                                <SampleAudioComponent
                                                                    key={_item.id}
                                                                    class={i} 
                                                                    item={_item}
                                                                    classIndex={index}
                                                                    itemIndex={_itemIndex}
                                                                    onDeleteImageSample={props.onDeleteSampleImage}
                                                                    >
                                                                </SampleAudioComponent>
                                                                )
                                                            )
                                                        }
                                                    </div>                                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )
                        }
                    {/* <!-- add box --> */}
                    <div className={cStyles.box_add} onClick={props.onAddClass}>
                        <h1>
                            <span className={[cStyles.icn_24, cStyles.icn_add].join(" ")}>add</span><span>{props.intl.formatMessage(messages.addclass)}</span>
                        </h1>
                    </div>

                </div>
                <div className={cStyles.box_dir}>
                </div>
                <div className={cStyles.box_center}>
                    {/* <!--  --> */}
                    <div className={[cStyles.box, cStyles.training].join(" ")}>
                        <div className={cStyles.title_bar}>
                            <h1 className={cStyles.title}><span>{props.intl.formatMessage(messages.training)}</span></h1>
                        </div>
                        <div className={cStyles.box_cont}>
                            <button type="button" onClick={props.onClickTrain} className={[cStyles.btn, cStyles.w100, handleTrainingStatusButtonColor(props.trainingTab.trainingStatus)].join(" ")}>{handleTrainingStatus(props, props.trainingTab.trainingStatus)}</button>
                            <div className={cStyles.process}>
                                {/* <span className={cStyles.duration}>00:00</span> */}
                                <span className={cStyles.percent}>{props.trainingTab.currentEpoch} / {props.trainingTab.totalEpoch}</span><br/>
                                <span className={cStyles.percent}>({props.intl.formatMessage(messages.model_lossrate)} : {props.trainingTab.loss}%)</span>
                            </div>
                        </div>
                        <div style={{backgroundColor: "white", marginBottom: "10px", borderRadius: "5px"}}>
                            { ( props.trainingTab.trainingStatus == "TRAINED" ) 
                                ?
                                <LineChart key={ResponsiveContainer.key}
                                    width={204} height={204} data={props.trainingTab.graphItems}
                                    margin={{ top: 20, right: 0, bottom: 0, left: -30 }}
                                    fill="#ffffff"
                                    >
                                    <CartesianGrid vertical={true} stroke="#f5f5f5" fill="#ffffff" />
                                    <XAxis dataKey="epoch" domain={[0, 'auto']} padding={{ right: 10 }} interval={9} tick={<XAxisTick/>}/>
                                    <YAxis dataKey="value" domain={[0, 100]} padding={{ top: 10 }} /*interval={0.2}*/ tick={<YAxisTick/>}/>
                                    <Tooltip
                                        wrapperStyle={{
                                            borderColor: 'white',
                                            boxShadow: '2px 2px 3px 0px rgb(204, 204, 204)',
                                        }}
                                        contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                                        labelStyle={{ fontWeight: 'bold', color: '#666666' }}
                                        formatter={(value) => ( [ value + '%', props.intl.formatMessage(messages.model_lossrate) ] )}
                                        label={props.intl.formatMessage(messages.model_lossrate)}
                                    />
                                    <Line dataKey="value" stroke="#ff7300" dot={false} />
                                </LineChart>
                                :
                                null
                            }
                        </div>
                        <div className={cStyles.advance_opt}>
                            <label
                                className={props.trainingTab.isAdvancedCollapse?cStyles.collapse:''}
                                onClick={props.onBtnAdvancedCollapseClick}
                            >{props.intl.formatMessage(messages.advanced)}</label> {/* <!-- toggle class "collapse" for open advanced opt--> */}
                            <ul>
                                <li>
                                    <label>Epoch (학습 반복 횟수):</label>
                                    <input 
                                        type="number" 
                                        min="1" 
                                        className={cStyles.h30} 
                                        value={props.trainingTab.epochs}
                                        step={1}
                                        onChange={props.onChangedEpoch}
                                        />
                                    <span onClick={() => props.onBtnTermHelp(0)} className={[cStyles.icn_24, cStyles.icn_info_23].join(" ")} >info</span>
                                    {
                                        props.trainingTab.showOptionPopupEpoch == true ?
                                        <ul className={cStyles.training_pop_option} onBlur={props.onHidePopup}>
                                            <li onClick={() => props.onBtnTermHelp(0)}><label className={cStyles.help_label}>
                                                {props.intl.formatMessage(messages.epochDesc)}
                                                {/*
                                                    <b>Epoch (학습 반복 횟수)</b><br/><br/>
                                                    입력한 데이터를 신경망에서 입력층부터 출력층까지 모든층을
                                                    몇 번씩 학습할 것인지 정합니다. 모든 입력 샘플 데이터를 1번씩
                                                    학습하는 것을 1에포크라고 합니다.<br/><br/>
                                                    같은 데이터를 여러 번 학습해야 신경망 모델이 잘 학습되어 정교화됩니다.
                                                    그러나 무조건 많이 학습하면 과도하게 학습되어 잘못된 결과를 나타내기
                                                    때문에 좋은 것은 아닙니다. 
                                                    적절한 에포크를 찾기 위해 여러번 학습해봐야 하며,
                                                    보통 30~100번의 에포크가 좋습니다.
                                                */}                                                
                                                </label></li>
                                        </ul>: null                                                
                                    }
                                </li>
                                <li>
                                    <label>Batch Size (데이터 분할 크기):</label>
                                    <select style={{ width: "80px", height: "30px", backgroundColor: "#DBEAFF", borderRadius : "5px" }} onChange={props.onChangedBatchSize} value={props.trainingTab.batchSize} >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="4">4</option>
                                        <option value="8">8</option>
                                        <option value="16">16</option>
                                        <option value="32">32</option>
                                        <option value="64">64</option>
                                    </select>
                                    <span onClick={() => props.onBtnTermHelp(1)} className={[cStyles.icn_24, cStyles.icn_info_23].join(" ")}>info</span>
                                    {
                                        props.trainingTab.showOptionPopupBatchSize == true ?
                                        <ul className={cStyles.training_pop_option} onBlur={props.onHidePopup}>
                                            <li onClick={() => props.onBtnTermHelp(1)}><label className={cStyles.help_label}>
                                            {props.intl.formatMessage(messages.batchsizeDesc)}
                                            {/*
                                                <b>Batch Size (데이터 분할 크기)</b><br/><br/>
                                                전체 입력 샘플 데이터를 한번에 학습하면 입력 양이 너무 많기 때문에 메모리도 
                                                부족할 수 있고 학습 속도도 저하될 수 있습니다. 
                                                따라서 입력 샘플 데이터를 여러개의 덩어리로 나누어서 
                                                각각 학습하는데 이 때 나누는 덩어리의 크기가 배치 크기입니다.<br/><br/>
                                                만약 전체 입력 샘플 크기가 1,000이고 배치 크기가 10이면 총 100개의 
                                                덩어리로 나누어서 학습합니다. 
                                                학습이 잘 안되거나 학습속도가 느리면 배치 크기를 조절해보세요.
                                            */}
                                                </label></li>
                                        </ul>: null                                 
                                    }
                                </li> 
                                {/**                               
                                <li>
                                    <label>Learning Rate (학습률):</label><br />
                                    <input 
                                        type="number" 
                                        min="0.001" 
                                        className={[cStyles.h30, cStyles.learning_rate].join(" ")} 
                                        value={props.trainingTab.learningRate}
                                        step={0.001}
                                        onChange={props.onChangedLearningRate}
                                        />
                                    <span onClick={() => props.onBtnTermHelp(2)} className={[cStyles.icn_24, cStyles.icn_info_23].join(" ")}>info</span>
                                    {
                                        props.trainingTab.showOptionPopupLearning == true ?
                                        <ul className={cStyles.training_pop_option} onBlur={props.onHidePopup}>
                                            <li onClick={() => props.onBtnTermHelp(2)}><label className={cStyles.help_label}>
                                            <b>Learning Rate (학습률)</b><br/><br/>
                                            학습률은 한번 학습할 때 어느 정도의 비율로 학습을 할 것인지를 의미합니다.
                                            학습은 충분히 작은 값으로 서서히 여러번 학습해야 학습이 잘 됩니다.
                                            처음부터 너무 큰 학습률로 학습하면 모델 학습이 제대로 안되고,
                                            또 너무 작은 학습률로 학습하면 학습에 너무 많은 시간이 걸립니다. 
                                            <br/><br/>
                                            보통의 경우 적절한 학습율은 0.01에서 0.001이며 학습이 잘 안될 때에는
                                            학습률을 늘리거나 줄이면서 학습이 잘되는 학습률을 찾아야합니다.
                                            </label></li>
                                        </ul>: null                                 
                                    }
                                </li>
                                **/}
                                <li className={cStyles.reset}>
                                    <label onClick={props.onBtnResetDefaults} >{props.intl.formatMessage(messages.resetdefaults)}</label>
                                </li>
                                {/* <li className={cStyles.under_the_hood}>
                                    <label>Under the hood</label>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                        {/* <div className={cStyles.box_right}>
                            
                            <div className={[cStyles.box, cStyles.preview].join(" ")}>
                                <div className={cStyles.title_bar}>
                                    <h1 className={cStyles.title}><span>Preview</span></h1>
                                </div>
                                <div className={cStyles.box_cont}>
                                    <button type="button" className={[cStyles.btn, cStyles.w100].join(" ")}><span className={cStyles.icn_apply}>apply</span><span>Apply Model</span></button>
                                </div>
                                <div className={cStyles.txt_notice}>
                                    <label>You must train a model on the left <strong>before</strong> you can preview it here.</label> 
                                </div>
                            </div>

                        </div> */}
                </div>
                <div className={cStyles.box_dir}>
                </div>
                <div className={cStyles.box_right}>
                
                    <div className={[cStyles.box, cStyles.preview].join(" ")}>
                        <div className={cStyles.title_bar}>
                            <h1 className={cStyles.title}><span>{props.intl.formatMessage(messages.preview)}</span></h1>
                        </div>
                        <div className={cStyles.box_cont}>
                            <button type="button" onClick={props.onBtnApplyModel} className={[cStyles.btn, cStyles.w100, handlePreviewStatusColor(props.previewTab.previewStatus)].join(" ")}><span className={handlePreviewStatusIcon(props.previewTab.previewStatus)}>apply</span><span>{handlePreviewStatus(props, props.previewTab.previewStatus)}</span></button>
                        </div>
                        {/* <!-- toggle class "collapse" for open advanced opt--> */}
                        <div className={[cStyles.txt_notice, cStyles.hide].join(" ")}>
                            <label>You must train a model on the left <strong>before</strong> you can preview it here.</label> 
                        </div>

                        <div className={cStyles.preview_option}>
                            {/* <span className={[cStyles.icn_32].join(" ")}>오디오 입력</span> */}
                            <h1><label>{props.intl.formatMessage(messages.input)}</label></h1>
                            <input
                                type="checkbox"
                                // defaultChecked={props.previewTab.micOn}
                                checked={props.previewTab.micOn}
                                onChange={props.onChangedMicOnOff}
                                disabled={props.trainingTab.trainingStatus != "TRAINED"}>
                            </input>
                        </div>
                        { /* 예측하기 마이크 파트, 마이크 또는 업로드 둘중에 하나 보여주기 */ }
                        <div className={cStyles.wrap_func_upload}> 
                            <h1 className={cStyles.title01} /*style={handlePreviewCameraUploadUI2( props.previewTab.previewInputType == "0")}*/><span className={[cStyles.icn_32, cStyles.icn_mic_23].join(" ")}>{props.intl.formatMessage(messages.mic)}</span><span>{props.intl.formatMessage(messages.mic)}</span></h1>
                            <div className={cStyles.wrap_via_webcam} style={{ visibility: "visible", textAlign: "center" }}>
                                {/* <div id="previewWebCamArea" className={cStyles.webcam}> */}
                                    {/* <button type="button" className={cStyles.btn_crop}>crop</button> */}
                                    {/* <button type="button" className={cStyles.btn_flip}>flip</button> */}
                                {/* </div> */}
                                <div id="previewSpectogramArea" className={cStyles.spectogram}>
                                </div>                                
                            </div>
                        {/* </div> */}
                        { /* 예측하기 업로드 파트, 마이크 또는 업로드 둘중에 하나 보여주기 */ }
                        {/* <div className={cStyles.wrap_func_upload} >  */}
                        </div>
                        {
                            // 아래 클래스별로, 분류 결과 값 보여주는 그래프
                            <div className={cStyles.wrap_class}>
                                {
                                    props.previewResults.map( ( item ) => {
                                        return (
                                        <div key={item.className} className={cStyles.class} >
                                            <label>{item.className}</label>
                                            <div className={cStyles.percent}>
                                                {
                                                    item.value != 0 ? ( <span style={{width: item.value + "%"}}>{item.value}%</span> ) : ( null )
                                                }
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
);

MLAudioEditor.propTypes = {
    
    onOpen: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    saveFile: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onShowWebCam: PropTypes.func.isRequired,
    onAddClass: PropTypes.func.isRequired,
    onBtnOptionClicked: PropTypes.func.isRequired,
    onDeleteClass: PropTypes.func.isRequired,
    onDisableClass: PropTypes.func.isRequired,
    onRemoveAllSample: PropTypes.func.isRequired,
    onDownloadSample: PropTypes.func.isRequired,
    onHidePopup: PropTypes.func.isRequired,
    onCloseWebCam: PropTypes.func.isRequired,
    onBtnImageUploadClicked: PropTypes.func.isRequired,
    onCloseFileUpload: PropTypes.func.isRequired,
    onClassNameChange: PropTypes.func.isRequired,
    onKeyEnterPress: PropTypes.func.isRequired,
    onUploadImage: PropTypes.func.isRequired,
    onBtnNewClicked: PropTypes.func.isRequired,
    onBtnAdvancedCollapseClick: PropTypes.func.isRequired,
    onRecordAudio: PropTypes.func.isRequired,
    onApplyRecordAudio: PropTypes.func.isRequired,
    onSampleImage:  PropTypes.func.isRequired,
    onClickTrain: PropTypes.func.isRequired,
    onChangedEpoch: PropTypes.func.isRequired,
    onChangedBatchSize: PropTypes.func.isRequired,
    onChangedLearningRate : PropTypes.func.isRequired,    
    onBtnResetDefaults : PropTypes.func.isRequired,
    onBtnApplyModel : PropTypes.func.isRequired,
    onDeleteSampleImage : PropTypes.func.isRequired,
    onAddedClassUploadImage : PropTypes.func.isRequired,
    onAddedPreviewUploadImage : PropTypes.func.isRequired,
    onBtnTermHelp : PropTypes.func.isRequired,
    onChangedMicOnOff : PropTypes.func.isRequired,
    previewResults: PropTypes.array.isRequired
};

export default injectIntl(MLAudioEditor);
