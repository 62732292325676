import bindAll from 'lodash.bindall';
import * as p5 from "p5";
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import MLImageEditorComponent from '../components/mlimage-editor/mlimage-editor.jsx';
import { activateTab, BLOCKS_TAB_INDEX } from '../reducers/editor-tab';
import { projectTitleInitialState } from '../reducers/project-title';

const JpegCoder = require('jpeg-js');

const messages = defineMessages({
    class: {
        id: 'gui.mlimage.class',
        description: '',
        defaultMessage: "Class"
    },
    msg1: {
        id: 'gui.mlimage.msg1',
        description: '',
        defaultMessage: "Learn after creating 2 or more classes with sample images added."
    },
    msg2: {
        id: 'gui.mlimage.msg2',
        description: '',
        defaultMessage: "Learn after adding sample images to your class."
    },
    msg3: {
        id: 'gui.mlimage.msg3',
        description: '',
        defaultMessage: "Do you like to create a new image learning model?"
    },
    msg4: {
        id: 'gui.mlimage.msg4',
        description: '',
        defaultMessage: "Click 'Add Extension Button' in the Code tab to add the Macine Learning audio block."
    }
});

class MLImageEditor extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleOpen',
            'handleSave',
            'handleSaveFile',
            'handleEdit',
            'handleAddClass',
            'handleClassOptionClick',
            'handleDeleteClass',
            'handleDisableClass',
            'handleRemoveAllSample',
            'handleDownloadSample',
            'handleHidePopup',
            'handleShowWebCam',
            'handleCloseWebCam',
            'handleImageUploadHideShow',
            'handleClassNameChange',
            'handleBtnEditName',
            'handleKeydownEnter',
            'handleUploadImages',
            'handleResetPage',
            'handleBtnAdvancedCollapseClick',
            'handleWebCamImgCapture',
            'handleTrain',
            'handleEpoch',
            'handleBatchSize',
            'handleLearningRate',
            'handleResetDefaults',
            'handleApplyModel',
            'handlePreviewWebCam',
            'trainingCallback',
            '_getExtention',
            'startPreviewRecongnition',
            'stopPreviewRecongnition',
            '_processPreviewInternal',
            'createMLData',
            'handleDeleteImageSample',
            'handlePreviewInput',
            'handleAddedClassUploadImage',
            'handleAddedPreviewUploadImage',
            // 'handlePreviewPostUploadedImage',
            'handleBtnTermHelp'
        ]);
        this.className = this.props.intl.formatMessage(messages.class);
        this.state = this.createMLData();
    }

    createMLData() {
        return {
            class: [
                {
                    className: this.className + '1',
                    content: 'Add Images Sample',
                    images: [],
                    showOptionPopup: false,
                    showWebCam: false,
                    showFileUpload: false,
                    isEditing: false,
                    webCam: {
                        canvas: null,
                        myp5: null,
                        video: null,
                        images: [],     // images[0] = { "id" : "클래스1-0", "data" : "~~~" }
                        imageLastId: 0
                    }
                },
                {
                    className: this.className + '2',
                    content: 'Add Images Sample',
                    images: [],
                    showOptionPopup: false,
                    showWebCam: false,
                    showFileUpload: false,
                    isEditing: false,
                    webCam: {
                        canvas: null,
                        myp5: null,
                        video: null,
                        images: [],
                        imageLastId: 0
                    }
                }
            ],
            trainingTab: {
                trainingStatus : "INIT",
                isAdvancedCollapse: false,
                learningRate: 0.005,
                epochs: 30,
                batchSize: 1,
                showOptionPopupEpoch: false,
                showOptionPopupBatchSize: false,
                showOptionPopupLearning: false,
                currentEpoch: 0,
                totalEpoch: 30,
                loss: "0.0",
                graphItems: [{ epoch: 0,  value: 100} ]
            },
            previewTab: {
                showWebCam: false,
                webCam: {
                    canvas: null,
                    myp5: null,
                    video: null,
                    image: null
                },
                previewStatus : "INIT",
                previewInputType : 0                // 0 카메라, 1 업로드
            },
            previewResults: []
        };
    }

    // componentDidMount () {
    //     console.info("aaa");
    // }

    /**
     * ML 이미지 말고 다른 탭을 눌렀을 때, 카메라를 모두 끈다.( 성능을 위해서 )
     * @param {*} newProps 
     */
    componentWillReceiveProps (newProps) {
        let _newClass = [...this.state.class];
        // 나머지 카메라 끄기
        for( let i = 0; i < _newClass.length; i++ ) {
            if ( _newClass[ i ].showWebCam ) {
                this.webCamSetup( _newClass, i );
            }
        }

        this.stopPreviewRecongnition();

        this.state.trainingTab.trainingStatus = "INIT";
        this.state.previewTab.previewStatus   = "INIT";
        if( this.state.previewTab.showWebCam ) {
            this.state.previewTab.showWebCam      = false;
            try{
                this.state.previewTab.webCam.video.remove();
                this.state.previewTab.webCam.video  = null;
            } catch( e ) {
            }
            try{
                this.state.previewTab.webCam.myp5.remove();
                this.state.previewTab.webCam.myp5   = null;
            } catch( e ) {
            }
            try{
                this.state.previewTab.webCam.canvas.remove();
                this.state.previewTab.webCam.canvas = null;
            } catch( e ) {
            }
        }
        this.state.previewResults   = [];
        this.setState({
            class: _newClass,
            trainingTab: this.state.trainingTab,
            previewTab:  this.state.previewTab,
            previewResults: []
        });
    }
    // componentWillUnmount () {
    //     console.info("ccc");
    // }

    // ================================
    
    handleOpen ( fileDataText ) {
        const extention = this._getExtention();

        // 기존 UI 초기화
        {
            this.stopPreviewRecongnition();

            for( let i = 0; i < this.state.class.length; i++ ) {
                const clazz = this.state.class[ i ];
                if( clazz.webCam.video ) {
                    clazz.webCam.video.remove();
                }
                if( clazz.webCam.canvas ) {
                    clazz.webCam.canvas.remove();
                }
                if ( clazz.webCam.myp5 ) {
                    clazz.webCam.myp5.remove();
                }
            }

            const pt = this.state.previewTab;
            pt.showWebCam   = false;
            if( pt.webCam.video ) {
                pt.webCam.video.remove();
            }
            if( pt.webCam.canvas ) {
                pt.webCam.canvas.remove();
            }
            if( pt.webCam.myp5 ) {
                pt.webCam.myp5.remove();
            }
            this.state = this.createMLData();
            this.state.trainingTab.currentEpoch   = 0; 
            this.state.trainingTab.totalEpoch     = this.state.trainingTab.epochs;             
            this.state.trainingTab.graphItems     = [{ epoch: 0,  value: 100}];
            this.state.trainingTab.loss           = "0.0";
            this.setState({
                class: this.state.class,
                trainingTab: this.state.trainingTab,
                previewTab: this.state.previewTab,
                previewResults: this.state.previewResults
            });            
        }

        // 불러오기
        {
            const mldata    = this.createMLData();
            mldata.class    = [];
    
            const openFileJson  = JSON.parse( fileDataText );
    
            // 클래스 세팅
            for( let i = 0; i < openFileJson.classItems.length; i++ ) {
                const classItem = openFileJson.classItems[ i ];
    
                let imageLastId = 0;
                const imageDataItems = [];
                for( let j = 0; j < classItem.webCam.images.length; j++ ) {
                    const image_arr = extention.base64ToUint8Array( classItem.webCam.images[ j ] );
                    imageDataItems.push( 
                        { 
                            id : classItem.className + "-" + imageLastId++, 
                            data : image_arr
                        });
                }
    
                const item = 
                {
                    className: classItem.className,
                    content: classItem.content,
                    images: [],
                    showOptionPopup: false,
                    showWebCam: false,
                    showFileUpload: false,
                    isEditing: false,
                    webCam: {
                        canvas: null,
                        myp5: null,
                        video: null,
                        images: imageDataItems,
                        imageLastId : imageLastId
                    }
                };
                mldata.class.push( item );
            }
    
            // 학습하기 세팅, kevin, 2020-12-05, 불러오기 시, learningRate, epochs는 세팅하지 않고, 디폴트로 쓴다.
            // mldata.trainingTab.learningRate     =   openFileJson.trainingTab.learningRate;
            // mldata.trainingTab.epochs           =   openFileJson.trainingTab.epochs;
            // mldata.trainingTab.batchSize        =   openFileJson.trainingTab.batchSize;
            
            this.state.class        = mldata.class;
            this.state.trainingTab  = mldata.trainingTab;
    
            this.setState({
                class: mldata.class,
                trainingTab: mldata.trainingTab
            })
        }

    }

    handleSave () {

    }    

    handleSaveFile() {
        return new Promise( resolve => {
            
            const extention = this._getExtention();

            const fileData  = {
                classItems : [],
                trainingTab : {
                    learningRate : 0,
                    epochs : 0,
                    batchSize : 0,
                }
            };

            // 클래스 세팅
            for( let i = 0; i < this.state.class.length; i++ ) {
    
                const image_data = [];
                for( let j = 0; j < this.state.class[ i ].webCam.images.length; j++ ) {

                    let   image_array = [];
                    const d = this.state.class[ i ].webCam.images[ j ].data;
                    if( d instanceof Uint8Array ) {
                        image_array = d;
                    } else {
                        const s = (p) => {
                            let id = null;
                            p.preload = () => {
                                p.pixelDensity( 1 );
                                let img     = d;
                                p.createCanvas( 100, 100 );
                                p.image( img, 0, 0, 100, 100 );
                                p.loadPixels();
                                image_array = p.pixels;
                            }
                            // p.draw = () => {
                            // }
                        };
                        let tempP5 = new p5(s );
                        tempP5.remove();
                        // image_array = d.canvas.getContext("2d").getImageData(0,0,100,100).data;
                    }
                    const base64Text = extention.uint8ArrayToBase64( image_array );
                    image_data.push( base64Text );
                }
    
                const classData = {
                    className: this.state.class[ i ].className,
                    content:   this.state.class[ i ].content,
                    webCam: {
                        images: image_data,
                    }            
                }
                fileData.classItems.push( classData );
            }
    
            // 학습하기 세팅
            fileData.trainingTab.learningRate   =   this.state.trainingTab.learningRate;
            fileData.trainingTab.epochs         =   this.state.trainingTab.epochs;
            fileData.trainingTab.batchSize      =   this.state.trainingTab.batchSize;

            const blob = new Blob([JSON.stringify(fileData, null, 2)], {type : 'application/json'});

            if (window.bitbrick) {
                var _this2 = this;
                var arrayBuffer, uint8Array;
                var fileReader = new FileReader();
                fileReader.onload = function() {
                    arrayBuffer = this.result;
                    uint8Array  = new Uint8Array(arrayBuffer);
                    const data_size = uint8Array.length;
                    const paketSize = 1024 * 32;
                    const s_count = (data_size / paketSize);

                    //if(s_count > 1)
                    {
                        var is_save = true;
                        var vInt = parseInt(s_count);

                        var projectFilename;
                        if( _this2.props.projectFilename && ( _this2.props.projectFilename !== '' ) ) {
                            projectFilename = _this2.props.projectFilename;
                        } else {
                            projectFilename = "Scratch3";
                        }

                        for (var i = 0; i < vInt; i++) {

                            const resJson_save = JSON.parse( window.bitbrick.save_image( projectFilename, "mli",i, vInt+1, paketSize, new Uint8Array( uint8Array.slice(i*paketSize,(i+1)*paketSize) ) ));

                            if( resJson_save.result ) {
                                // 정상
                            } else {
                                // Save 에러,  안드로이드 저장 권한 체크가 않된 경우 발생         
                                console.error( "save error - " + resJson_save );
                                is_save = false;
                                break;
                            }
                        }
                        if(is_save)    
                        {
                            const resJson_save = JSON.parse( window.bitbrick.save_image( projectFilename, "mli",vInt, vInt+1,data_size -  vInt*paketSize, new Uint8Array( uint8Array.slice(vInt*paketSize, data_size) ) ));

                            if( resJson_save.result ) {
                                // 정상
                            } else {
                                // Save 에러,  안드로이드 저장 권한 체크가 않된 경우 발생         
                                console.error( "save error - " + resJson_save );
                            }
                        }
                        

                    }
                   
                   // var array_tttt = new Uint8Array([0x04, 0x06, 0x07, 0x08]);
                   

                };
                fileReader.readAsArrayBuffer(blob);
                                
            } else {
                resolve( blob );
            }
        });     
    }


    // ================================


    handleEdit (e) {
    }

    handleAddClass (e) {
        this.handleHidePopup(e);

        let index   = 1;
        for( let i = 0; i < this.state.class.length; i++ ) {
            let clazzName = this.state.class[ i ].className;
            if( clazzName.indexOf( this.className ) == 0 ) {
                // 기존에 있는 클래스 이름에서 중복된것을 찾아서, 중복되지 않게 한다.
                let _i = parseInt( clazzName.substring( this.className.length, clazzName.length ) );
                if( index <= _i ) {
                    index = _i + 1;
                }
            } 
        }
        const mldata = this.createMLData();
        mldata.class[ 0 ].className = ( this.className + index );

        let newClass = [
            ...this.state.class,
            mldata.class[ 0 ]
        ];
        this.setState({
            class: newClass
        })
    }
    handleClassOptionClick (index) {
        let newClass = [...this.state.class];
        newClass.forEach( (e, i) => { if (i != index) e.showOptionPopup = false })
        newClass[index].showOptionPopup = newClass[index].showOptionPopup?false:true;
        this.setState({
            class: newClass
        });
    }

    // {
    //     className: '클래스1',
    //     content: 'Add Images Sample',
    //     images: [],
    //     showOptionPopup: false,
    //     showWebCam: false,
    //     showFileUpload: false,
    //     isEditing: false,
    //     webCam: {
    //         canvas: null,
    //         myp5: null,
    //         video: null,
    //         images: [],     // images[0] = { "id" : 0, "data" : "~~~" }
    //         imageLastId: 0
    //     }
    // }
    handleDeleteClass (index) {
        let newClass1 = [...this.state.class];
        newClass1[ index ].showWebCam   = false;
        newClass1[ index ].images           = [];
        newClass1[ index ].webCam.images    = [];
        newClass1.forEach( e => { 
            if( e.webCam.video ) {
                e.webCam.video.remove();
            }
            if( e.webCam.canvas ) {
                e.webCam.canvas.remove();
            }
            if ( e.webCam.myp5 ) {
                e.webCam.myp5.remove();
            }
            e.images          = [...e.images];
            e.webCam.images   = [...e.webCam.images];
            e.showOptionPopup = false;
            e.showWebCam      = false;
            e.showFileUpload  = false;
            e.isEditing       = false;            
        });
        this.setState({
            class: newClass1
        }, () => {
            let newClass2 = [...this.state.class];
            newClass2.splice(index, 1)
            this.setState({
                class: newClass2
            });
        });
    }
    
    handleDisableClass (index) {

    }

    handleRemoveAllSample (index) {
        this.handleHidePopup();
        let newClass = [...this.state.class];
        if( newClass[index].webCam ) {
            newClass[index].webCam.images = [];
        }
        this.setState({
            class: newClass
        });
    }

    handleDownloadSample (index) {
    
    }

    handleTrain() {
        this.handleHidePopup();

        let classImageCount     = 0;
        let noImageClassItems   = [];
        // 나머지 카메라 끄기
        for( let i = 0; i < this.state.class.length; i++ ) {
            if( this.state.class[ i ].webCam.images.length == 0  ) {
                noImageClassItems.push( this.state.class[ i ].className );
            } else {
                classImageCount += 1;   // 클래스별로 이미지가 있으면, 1을 더하여, 이미지가 있는 클래스 전체 개수가 2 이상이어야, 학습이 가능하기 때문.
            }
        }

        // 샘플 이미지가 있는 클래스가 최소 두개 이상이어야 학습이 된다. (ML5에서 에러남)
        if( classImageCount < 2 ) {
            alert( this.props.intl.formatMessage(messages.msg1) );
            return;
        }        

        // 클래스에 샘플 이미지가 없을때, 
        if( noImageClassItems.length > 0 ) {
            const alertMsg  = noImageClassItems.join();
            alert( this.props.intl.formatMessage(messages.msg2) );
            return;
        }

        // 초기상태와, 학습완료 상태만 학습하기를 할 수 있으며, 
        // PRE_TRAINING, TRAINING 중에는 학습하기를 다시 하지 않는다.
        if( !( this.state.trainingTab.trainingStatus == "INIT" ||
            this.state.trainingTab.trainingStatus == "TRAINED" ) ) {
            return;
        }

        this.stopPreviewRecongnition();

        let _newClass = [...this.state.class];
        // 나머지 카메라 끄기
        for( let i = 0; i < _newClass.length; i++ ) {
            if ( _newClass[ i ].showWebCam ) {
                this.webCamSetup( _newClass, i );
            }
        }
        

        let newTrainingTab    = {...this.state.trainingTab};
        let newPreviewTab     = {...this.state.previewTab};

        newTrainingTab.trainingStatus = "PRE_TRAINING";
        newTrainingTab.currentEpoch   = 0; 
        newTrainingTab.totalEpoch     = this.state.trainingTab.epochs;             
        newTrainingTab.graphItems     = [{ epoch: 0,  value: 100}];
        newTrainingTab.loss           = "0.0";
        newPreviewTab.previewStatus   = "INIT";
        if( newPreviewTab.previewInputType == 0 ) {        // 테스트
            newPreviewTab.showWebCam      = false;
            try{
                newPreviewTab.webCam.video.remove();
                newPreviewTab.webCam.video  = null;
            } catch( e ) {
            }
            try{
                newPreviewTab.webCam.myp5.remove();
                newPreviewTab.webCam.myp5   = null;
            } catch( e ) {
            }
            try{
                newPreviewTab.webCam.canvas.remove();
                newPreviewTab.webCam.canvas = null;
            } catch( e ) {
            }
        } else {
            try{
                newPreviewTab.webCam.image = [];
            } catch( e ) {
            }
        }

        this.state.previewResults   = [];
        this.setState({
            class: _newClass,
            trainingTab: newTrainingTab,
            previewTab:  newPreviewTab,
            previewResults: []
        });

        let classNames  = [];
        let classItems  = [];
        let newClass    = [...this.state.class];
        for( let i = 0; i < newClass.length; i++ ) {
            let _class = newClass[ i ];
            classNames.push( _class.className );
            
            let tempItems   = [];
            for( let j = 0; j < _class.webCam.images.length; j++ ) {

                // =========================================================================
                const s = (p) => {
                    p.setup = () => {
                        p.pixelDensity( 1 );
                        let data;
                        let img     = _class.webCam.images[ j ].data;
                        if( img instanceof Uint8Array ) {
                            let _img = p.createImage(100, 100);
                            _img.loadPixels();
                            p.createCanvas( 100, 100 );
                            for( let i=0; i < img.length; i++ ) {
                                _img.pixels[ i ] = img[ i ];
                            }
                            _img.updatePixels();
                            p.image( _img, 0, 0, 100, 100 );
                            _img.loadPixels();
                            data = _img.pixels;
                        } else {
                            p.createCanvas( 100, 100 );
                            p.image( img, 0, 0, 100, 100 );
                            p.loadPixels();
                            data = p.pixels;                                    
                        }

                        // var rawImageData = {
                        //     data: data,
                        //     width: 100,
                        //     height: 100,
                        // };
            
                        // var jpegImageData = JpegCoder.encode(rawImageData, 150);
                        // var blob = new Blob([new Uint8Array(jpegImageData.data)], {type: "application/octet-stream"});
                        // downloadBlob(_class.className + '_' + j + '.jpg', blob);       

                        let classItem = {
                            name : _class.className,
                            data : data
                        };
                        classItems.push( classItem );                                                        
                    }
                    // p.draw = () => {
                    // }
                };
                tempItems.push( new p5(s) );


                // =========================================================================
                // let img     = _class.webCam.images[ j ]
                // // let newImg	= _class.webCam.myp5.createCanvas( 100, 100 );
                // // newImg.hide();
                // _class.webCam.myp5.image( img, 0, 0, 265, 265 );
                // _class.webCam.myp5.loadPixels();
                // let data    = _class.webCam.myp5.pixels;

                // var rawImageData = {
                //     data: data,
                //     width: 265,
                //     height: 265,
                // };
    
                // var jpegImageData = JpegCoder.encode(rawImageData, 150);
                // var blob = new Blob([new Uint8Array(jpegImageData.data)], {type: "application/octet-stream"});
                // downloadBlob(_class.className + '_' + j + '.jpg', blob);          

                // let classItem = {
                //     name : _class.className,
                //     data : data
                // };
                // classItems.push( classItem );                        
            }

            for( let i = 0; i < tempItems.length; i++ ) {
                let t = tempItems[ i ];
                t.remove();
                t = null;
            }

        }
        // * [
        // *  {
        // *    "name" : "class1",
        // *    "data" : []
        // *  },
        // *  {
        // *    "name" : "class2",
        // *    "data" : []
        // *  },
        // * ]
        let learningRate    = this.state.trainingTab.learningRate;
        let trainingOptions = {
            epochs:     this.state.trainingTab.epochs,
            batchSize:  this.state.trainingTab.batchSize
        }

        setTimeout( ()=> {
            const extentionObject = this._getExtention();
            extentionObject
                .train( classNames, classItems, learningRate, trainingOptions, this.trainingCallback )
                .then( () => {
                    console.info( "training..." );
                }) ;
        }, 1000 );
    }

    /**
     * 
     * "INIT" status is not trained yet
     * 
     * @param {string} status   PRE_TRAINING : Prepare training
     *                          TRAINING     : Training
     *                          TRAINED      : Trained
     * @param {int} epochs  max epoch
     * @param {int} epoch   current epoch
     * @param {json} loss   value can be NaN
     *                      {
     *                          "acc"       : "",
     *                          "loss"      : "",
     *                          "val_acc"   : "",
     *                          "val_loss"  : "",
     *                      }
     */
    trainingCallback( status, epochs, epoch, loss ) {
        const ep = JSON.stringify( epoch );
        console.info(`status=${status}, epochs=${epochs}, epoch=${ep}, loss=${loss}` );
        
        this.state.trainingTab.trainingStatus = status;
        
        if( status == "TRAINED" ) {
            this.state.trainingTab.currentEpoch   = this.state.trainingTab.epochs; 
            this.state.trainingTab.totalEpoch     = this.state.trainingTab.epochs;             
            
            // previewInputType
            //  0 : 카메라
            //  1 : 업로드
            if( this.state.previewTab.previewInputType == 0 ) {
                // Case1. 예측하기 - 카메라 선택 시
                // training 완료 일때, 카메라 켜고, 학습을 자동으로 한다.

                // 카메를 자동으로 켠다.
                this.handlePreviewWebCam( "on" );
                // 카메라는 움직이므로, 쓰레드로 분류를 계속 한다.
                this.startPreviewRecongnition();
            } else {
                // Case2. 예측하기 - 업로드 선택 시
                // 업로드를 해서, classify를 해야한다.
                this.setState({
                    trainingTab: this.state.trainingTab,
                    previewTab:  this.state.previewTab
                });                
            }
        } else if( status == "TRAINING" ) {
            // training 중 일때, 에폭 숫자를 증가 시켜 보여준다.
            this.state.trainingTab.currentEpoch   = ( epoch + 1 ); 
            this.state.trainingTab.totalEpoch     = epochs;
            if( epoch != undefined ) {
                this.state.trainingTab.graphItems.push( { epoch : ( epoch + 1 ), value : ( loss.loss * 100 ).toFixed(1) } );
            }

            this.state.trainingTab.loss           = ( loss == undefined ? "0.0" : ( loss.loss * 100 ).toFixed(1) );
            this.setState({
                trainingTab: this.state.trainingTab
            });
        }

    }

    handleSampleImage ( thi, i, index, _item, _itemIndex ) {

        
        // let myp5 = new p5( p => {
        //     p.setup = function() {
        //         let constraints = {
        //             video: {
        //                 mandatory: {
        //                     // maxWidth: 250,
        //                     // maxHeight: 182,
        //                     maxWidth: 302,
        //                     maxHeight: 100,
        //                 }
        //             },  
        //             audio: false
        //         };
        
        //         let video = p.createCapture( constraints );
        //         video.size( 302, 100 );
        //         let cvs   = p.createCanvas( 302, 100 );
        //         cvs.hide();     // hide를 해야, UI가 깨지지 않고, video 아래에 canvas가 생기지 않음
        //         newClass[index].webCam.video = video;
        //         newClass[index].webCam.myp5  = p;
        //         //console.log('ml5: ', await ml5.bodyPix(video));
        //     }
        // }, 
        // webCamCanvasDiv
        // );
    }

    handleHidePopup (e) {
        let newClass = [...this.state.class];
        newClass.forEach( e => { e.showOptionPopup = false })
        this.setState({
            class: newClass
        });
    }

    webCamSetup ( newClass, index) {
        if (newClass[index].showWebCam) {
            // newClass[index].webCam.video.hide();
            if( newClass[index].webCam.video ) {
                newClass[index].webCam.video.hide();
                newClass[index].webCam.video.remove();
                newClass[index].webCam.myp5.remove();
            }
            newClass[index].webCam.video = null;
            newClass[index].showWebCam  = false;
        } else {
            let webCamCanvasDiv = document.getElementById('webCamArea_' + index);
            let myp5 = new p5( p => {

                let video;
                let cvs;
                let origin_height = 360;
                let a_width = 265;
                let s_width = 360;          
                let width_rate	= a_width / s_width;                      
                p.setup = function() {
                    let constraints = {
                        video: {
                            mandatory: {
        //                         maxWidth: 480,
        //                         maxHeight: 360,
                                minWidth: 480 * width_rate,
                                minHeight: 360 * width_rate
                            }
                        },  
                        audio: false
                    };
                    p.pixelDensity( 1 );
                    video = p.createCapture( constraints );
                    video.size( 480 * width_rate, 360 * width_rate );
                    video.hide();   //
        
                    cvs   = p.createCanvas( 360 * width_rate, 360 * width_rate );

                    newClass[index].webCam.canvas   = cvs;
                    newClass[index].webCam.myp5     = p;
                    newClass[index].webCam.video    = video;
                };
                p.draw = function() {
                    // 좌우 대칭으로 돌릴 때, x 축 조절
                    p.translate( 360 * width_rate, 0 );
                    // 좌우 대칭으로 돌리기
                    p.scale(-1, 1);
                    p.image( video, 0, 0, 480 * width_rate, 360 * width_rate, 60, 0, 360 * 2, 360 * 2 );      
                    p.loadPixels();
                };
            }, 
            webCamCanvasDiv
            );
            newClass[index].showWebCam  = true;
        }
    }

    handleWebCamImgCapture (index) {
        console.info( "handleWebCamImgCapture = " + index );
        let newClass = {...this.state.class};
        let webCam = newClass[index].webCam;
        let images = webCam.images;
        if ( webCam.video.loadedmetadata ) {
            
            let origin_height = 360;
            let a_width = 265;
            let s_width = 360;          
            let width_rate	= a_width / s_width;  

            let size    = 100;
            let img     = webCam.canvas.get( 
                    0, 0, 
                    origin_height * width_rate, origin_height * width_rate, 
                    0, 0, 
                    origin_height * width_rate, origin_height * width_rate
                    );
            // webCam.myp5.image( img, 0, 0, size, size );
            // webCam.myp5.loadPixels();

            images.push( { id : newClass[index].className + "-" + webCam.imageLastId++, data: img } );

            // let img     = webCam.video.get( 0,0, 100,100 );
            // webCam.myp5.image( img, 0, 0, 100, 100 );
            // webCam.myp5.loadPixels();
            // let mmm = webCam.myp5.pixels;
            // images.push( img );

            // var rawImageData = {
            //     data: mmm,
            //     width: 100,
            //     height: 100,
            // };

            // var jpegImageData = JpegCoder.encode(rawImageData, 150);
            // var blob = new Blob([new Uint8Array(jpegImageData.data)], {type: "application/octet-stream"});
            // downloadBlob('test.jpg', blob);                            
       }
       webCam.images = images;
        this.setState({
            webCam: newClass
        });
    }

    // handleWebCamImgCapture (index) {
    //     console.info( "handleWebCamImgCapture = " + index );
    //     let newClass = {...this.state.class};
    //     let pics = [];
    //     if ( newClass[index].webCam.video.loadedmetadata ) {

    //         let img = newClass[index].webCam.video.get();
            
    //         pics.push( img );
            
    //     }

    //     if ( pics.length > 0 ){
    //         let capture = newClass[index].webCam.myp5.image( pics[0], 0, 0, 0, 0 );
    //         console.log('capture: ', capture);
            
    //     }
    //     newClass[index].webCam.images = pics;
    //     this.setState({
    //         webCam: newClass
    //     });
    // }

    handleAddedClassUploadImage(classIndex, imageFiles) {
        let newClass = [...this.state.class];
        for( let i = 0; i < imageFiles.length; i++ ) {

            newClass[ classIndex ].webCam.images.push( 
                { 
                    id : newClass[ classIndex ].className + "-" + newClass[ classIndex ].webCam.imageLastId++,
                    data: imageFiles[ i ]
                }
             );
        }
        this.setState({
            class: newClass
        });
    }    

    handleShowWebCam ( index ) {
        this.handleHidePopup();
        let newClass = [...this.state.class];
        // 나머지 카메라 끄기
        for( let i = 0; i < newClass.length; i++ ) {
            if ( newClass[ i ].showWebCam ) {
                this.webCamSetup( newClass, i );
            }
            newClass[ i ].showFileUpload = false;
        }
        // 카메라 켜기
        this.webCamSetup( newClass, index );


        // 예측하기 카메라 인식 쓰레드 정지
        this.stopPreviewRecongnition();
       
        // 예측하기 카메라를 끈다.
        if( this.state.previewTab.showWebCam ) {
            this.state.previewTab.showWebCam      = false;
            if( this.state.previewTab.webCam.video ) {
                try{
                    this.state.previewTab.webCam.video.remove();
                    this.state.previewTab.webCam.video  = null;
                } catch( e ) {
                }
                try{
                    this.state.previewTab.webCam.myp5.remove();
                    this.state.previewTab.webCam.myp5   = null;
                } catch( e ) {
                }
                try{
                    this.state.previewTab.webCam.canvas.remove();
                    this.state.previewTab.webCam.canvas = null;
                } catch( e ) {
                }
            }

            this.state.trainingTab.trainingStatus = "INIT";
            this.state.previewTab.previewStatus   = "INIT";
        }

        this.state.previewResults   = [];
        this.setState({
            class: newClass,
            trainingTab: this.state.trainingTab,
            previewTab:  this.state.previewTab,
            previewResults: []
        });
    }


    handleCloseWebCam ( index ) {
        this.handleHidePopup();
        let newClass = [...this.state.class];
        if( newClass[ index ].showWebCam ) {
            // 카메라 켜기
            this.webCamSetup( newClass, index );
        }
        this.setState({
            class: newClass
        });        
    }

    handleImageUploadHideShow (index) {
        this.handleHidePopup();
        
        let newClass = [...this.state.class];
        let prev = newClass[ index ].showFileUpload;
        for( let i = 0; i < newClass.length; i++ ) {
            if ( newClass[ i ].showWebCam ) {
                this.webCamSetup( newClass, i );
            }
            newClass[ i ].showFileUpload = false;
        }
        newClass[ index ].showFileUpload = !prev;

        this.setState({
            class: newClass
        });
    }

    handleClassNameChange (e, index) {
        let newClass = [...this.state.class];
        newClass[index].className = e.target.value;
        this.setState({
            class: newClass
        });
    }

    handleBtnEditName (index) {
        this.handleHidePopup();
        let newClass = [...this.state.class];
        newClass[index].isEditing = newClass[index].isEditing?false:true;
        this.setState({
            class: newClass
        });
    }

    handleKeydownEnter (e) {
        e.persist();    // 이것을 넣어줘야, render의 html에서 key를 부여하여, onchange가 변화되더라도, 키가 잘 입력된다.
        if ( e.key == 'Enter' ) {
            let index = 0;
            for( let i = 0; i < this.state.class.length; i++ ) {
                if( this.state.class[ i ].isEditing == true ) {
                    index = i;
                }
            }
            let isDuplicate = false;
            const className = this.state.class[ index ].className;
            for( let i = 0; i < this.state.class.length; i++ ) {
                if( index != i ) {
                    let clazzName = this.state.class[ i ].className;
                    if( className == clazzName ) {
                        isDuplicate   = true;
                        break;
                    } 
                }
            }

            if( !isDuplicate ) {
                let newClass = [...this.state.class];
                newClass.forEach( e => { e.isEditing = false })
                this.setState({
                    class: newClass
                });
            }
        }
    }

    handleUploadImages (e) {
    }
    
    handleResetPage (e) {
        // let isDirty = false;
        // 각 클래스별로, 촬영한 또는 업로드된 이미지가 있는지 검사.
        // for( let i = 0; i < this.state.class.length; i++ ) {
        //     const clazz = this.state.class[ i ];
        //     if( clazz.webCam.images.length > 0 ) {
        //         isDirty = true;
        //     }
        // }

        
        let result = confirm( this.props.intl.formatMessage(messages.msg3) );
        if( result ) {
            this.stopPreviewRecongnition();

            for( let i = 0; i < this.state.class.length; i++ ) {
                const clazz = this.state.class[ i ];
                if( clazz.webCam.video ) {
                    clazz.webCam.video.remove();
                }
                if( clazz.webCam.canvas ) {
                    clazz.webCam.canvas.remove();
                }
                if ( clazz.webCam.myp5 ) {
                    clazz.webCam.myp5.remove();
                }
            }

            const pt = this.state.previewTab;
            pt.showWebCam   = false;
            if( pt.webCam.video ) {
                pt.webCam.video.remove();
            }
            if( pt.webCam.canvas ) {
                pt.webCam.canvas.remove();
            }
            if( pt.webCam.myp5 ) {
                pt.webCam.myp5.remove();
            }
            this.state = this.createMLData();
            this.state.trainingTab.currentEpoch   = 0; 
            this.state.trainingTab.totalEpoch     = this.state.trainingTab.epochs;             
            this.state.trainingTab.graphItems     = [{ epoch: 0,  value: 100}];
            this.state.trainingTab.loss           = "0.0";
            this.setState({
                class: this.state.class,
                trainingTab: this.state.trainingTab,
                previewTab: this.state.previewTab,
                previewResults: this.state.previewResults
            });
        }
    }

    handleBtnAdvancedCollapseClick () {
        let trainingTabNew = {...this.state.trainingTab};
        trainingTabNew.isAdvancedCollapse = trainingTabNew.isAdvancedCollapse?false:true;
        this.setState({
            trainingTab: trainingTabNew
        });
    }

    handleEpoch (e) {
        let trainingTabNew = {...this.state.trainingTab};
        trainingTabNew.epochs = e.target.value;
        this.setState({
            trainingTab: trainingTabNew
        });
    }    
    handleBatchSize (e) {
        let trainingTabNew = {...this.state.trainingTab};
        trainingTabNew.batchSize = parseInt( e.target.value );
        this.setState({
            trainingTab: trainingTabNew
        });
    }    
    handleLearningRate (e) {
        let trainingTabNew = {...this.state.trainingTab};
        trainingTabNew.learningRate = e.target.value;
        this.setState({
            trainingTab: trainingTabNew
        });
    }    
    handleResetDefaults (e) {
        const mldata    = this.createMLData();
        mldata.trainingTab.isAdvancedCollapse = true;
        this.setState({
            trainingTab: mldata.trainingTab
        });
    }    

    handleApplyModel () {
        const extentionObject = this._getExtention();
        if( extentionObject ) {
            // 학습하기가 완료될때만, 모델을 적용한다.
            if( this.state.trainingTab.trainingStatus == 'TRAINED' ) {
                this.stopPreviewRecongnition();
                this.handlePreviewWebCam( "off" );
                this.state.previewTab.previewStatus = "APPLIED";
                extentionObject.applyModel().then( () => {
                    this.setState({
                        previewTab : this.state.previewTab
                    });       
                });
                this.props.onActivateTab( BLOCKS_TAB_INDEX );  // 코드탭
            }
        }
    }

    _getExtention() {
        let extentionId_mlimage = this.props.vm.extensionManager._loadedExtensions.get( "mlimage" );
        if( extentionId_mlimage !== undefined ) {
            let extentionObject   = this.props.vm.extensionManager.getServices()[ extentionId_mlimage ];
            if( extentionObject !== null && extentionObject !== undefined ) {
                return extentionObject;
            }
        }
        alert( this.props.intl.formatMessage(messages.msg4) );
        return null;
    }
    
    /**
     * 
     * @param {string} onOff    "on", "off"
     */
    handlePreviewWebCam( onOff ) {
        const   previewTab = this.state.previewTab;

        if( onOff == "on" ) {
            if (!previewTab.showWebCam) {
                let webCamCanvasDiv = document.getElementById( 'previewWebCamArea' );
                let myp5 = new p5( p => {

                    let video;
                    let cvs;
                    let origin_height = 360;
                    let a_width = 265;
                    let s_width = 360;          
                    let width_rate	= a_width / s_width;      

                    p.setup = function() {
                        let constraints = {
                            video: {
                                mandatory: {
            //                         maxWidth: 480,
            //                         maxHeight: 360,
                                    minWidth: 480 * width_rate,
                                    minHeight: 360 * width_rate
                                }
                            },  
                            audio: false
                        };
                        p.pixelDensity( 1 );
                        video = p.createCapture( constraints );
                        video.size( 480 * width_rate, 360 * width_rate );
                        video.hide();   //                        
                        
                        cvs   = p.createCanvas( 360 * width_rate, 360 * width_rate );

                        previewTab.webCam.canvas    = cvs;
                        previewTab.webCam.video     = video;
                        previewTab.webCam.myp5      = p;
                    };
                    p.draw = function() {
                        // 좌우 대칭으로 돌릴 때, x 축 조절
                        p.translate( 360 * width_rate, 0 );
                        // 좌우 대칭으로 돌리기
                        p.scale(-1, 1);
                        p.image( video, 0, 0, 480 * width_rate, 360 * width_rate, 60, 0, 360 * 2, 360 * 2 );      
                        p.loadPixels();
                    };
                }, 
                webCamCanvasDiv
                );
                previewTab.showWebCam  = true;
            }
        } else {
            if ( previewTab.showWebCam) {
                // newClass[index].webCam.video.hide();
                if( previewTab.webCam.video ) {
                    previewTab.webCam.video.hide();
                    previewTab.webCam.video.remove();
                    previewTab.webCam.myp5.remove();
                    previewTab.webCam.canvas.remove();
                }
                previewTab.webCam.video = null;
                previewTab.showWebCam   = false;
            }
        }
        this.setState({
            previewTab: previewTab
        });

    }

    startPreviewRecongnition() {
        const newPreviewTab = this.state.previewTab;
        if( newPreviewTab.webCam ) {
            if( !newPreviewTab.showWebCam ) {
                this.handlePreviewWebCam( "on" );
            }
        } else {
            return;
        }

        this.stopPreviewRecongnition();
        
        this.intervalId = setInterval(() => {

            const webCam = newPreviewTab.webCam;
            if ( webCam.video.loadedmetadata ) {
                let previewData;
                const s = (p) => {

                    let origin_height = 360;
                    let a_width = 265;
                    let s_width = 360;          
                    let width_rate	= a_width / s_width;  

                    p.preload = () => {
                        let size    = 100;
                        p.pixelDensity( 1 );
                        p.createCanvas( size, size );
                        let img     = webCam.canvas.get( 
                                0, 0, 
                                origin_height * width_rate, origin_height * width_rate, 
                                0, 0, 
                                origin_height * width_rate, origin_height * width_rate
                                );

                        p.image( img, 0, 0, size, size );
                        p.loadPixels();
                        previewData = p.pixels;

                        const extentionObject = this._getExtention();
                        if( extentionObject ) {
                            extentionObject.classify( previewData, this._processPreviewInternal );
                        }                         

                        // p.createCanvas( 100, 100 );
                        // let img     = webCam.canvas.get( 0,0, 265,265 );
                        // p.image( img, 0, 0, 100, 100 );
                        // p.loadPixels();
                        // previewData = p.pixels;

                        // var rawImageData = {
                        //     data: previewData,
                        //     width: 100,
                        //     height: 100,
                        // };
            
                        // var jpegImageData = JpegCoder.encode(rawImageData, 150);
                        // var blob = new Blob([new Uint8Array(jpegImageData.data)], {type: "application/octet-stream"});
                        // downloadBlob( 'preview_img.jpg', blob);       

                    }
                    // p.draw = () => {
                        // }
                    };
                let t = new p5(s);
                t.remove();
                
                                                   
                    
                // // let img     = webCam.video.get( 0,0, 265,265 );
                // // webCam.myp5.image( img, 0, 0, 265,265 );
                // // webCam.myp5.loadPixels();
                // // let previewData = webCam.myp5.pixels;
                // let origin_height = 360;
                // let a_width = 265;
                // let s_width = 360;          
                // let width_rate	= a_width / s_width;  
    

                // let size    = 100;
                // let img     = webCam.canvas.get( 
                //         0, 0, 
                //         origin_height * width_rate, origin_height * width_rate, 
                //         0, 0, 
                //         origin_height * width_rate, origin_height * width_rate
                //         );
                // webCam.myp5.image( img, 0, 0, size, size );
                // webCam.myp5.loadPixels();
                // let previewData = webCam.myp5.pixels;

                // const extentionObject = this._getExtention();
                // if( extentionObject ) {
                //     extentionObject.classify( previewData, this._processPreviewInternal );
                // }
            }
        }, 300 );
    }

    _processPreviewInternal(error, result) {
        // console.info( error + ", " + result );
        // results
        // * 0: {blue-ish: 0.5556634664535522, label: "blue-ish", confidence: 0.5556634664535522}
        // * 1: {red-ish: 0.444336473941803, label: "red-ish", confidence: 0.444336473941803}

        let results = [];
        result.map( item => {
            results.push( {
                className: item.label,
                value: Math.round( item.confidence * 100 )
            } );
        });
        this.state.previewResults = results;
        this.setState({
            previewResults : this.state.previewResults
        });                        
    }

    stopPreviewRecongnition() {
        clearInterval(this.intervalId);
        this.intervalId = null;
    }


    handleDeleteImageSample( classIndex, imageId ) {
        let newClass  = [...this.state.class];
        let newImages = newClass[ classIndex ].webCam.images;
        newImages = newImages.filter(
            item => ( item.id != imageId )
            );
        newClass[ classIndex ].webCam.images = newImages;
        this.setState({
            class: newClass
        })
    }

    handleBtnTermHelp(num) {
        let prevStatus  = false;
        if( num == 0 ) {
            prevStatus  = this.state.trainingTab.showOptionPopupEpoch;
            this.state.trainingTab.showOptionPopupEpoch         = true;
            this.state.trainingTab.showOptionPopupBatchSize     = false;
            this.state.trainingTab.showOptionPopupLearning      = false;

            this.state.trainingTab.showOptionPopupEpoch         = !prevStatus;
        } else if ( num == 1)  {
            prevStatus  = this.state.trainingTab.showOptionPopupBatchSize;
            this.state.trainingTab.showOptionPopupEpoch         = false;
            this.state.trainingTab.showOptionPopupBatchSize     = true;
            this.state.trainingTab.showOptionPopupLearning      = false;            

            this.state.trainingTab.showOptionPopupBatchSize     = !prevStatus;
        } else if ( num == 2 ) {
            prevStatus  = this.state.trainingTab.showOptionPopupLearning;
            this.state.trainingTab.showOptionPopupEpoch         = false;
            this.state.trainingTab.showOptionPopupBatchSize     = false;
            this.state.trainingTab.showOptionPopupLearning      = true; 

            this.state.trainingTab.showOptionPopupLearning      = !prevStatus;
        } else {
        }
        this.setState({
            trainingTab: this.state.trainingTab
        });        
    }

    /**
     * 프리뷰에서 카메라 또는 업로드를 선택할 때(바뀔 때), 호출됨
     * 카메라인 경우, 쓰레드를 돌려서, 실시간으로 분류를 계속해야 한다
     * 업로드인 경우, 쓰레드를 멈춘다. 파일 업로드를 하는 경우, 분류를 한번 한다.
     * 
     */
    handlePreviewInput(e) {
        // e.target.value
        // 0 : 카메라
        // 1 : 업로드

        this.state.previewTab.previewInputType  = e.target.value;
        if( e.target.value == 0 ) {
            if( this.state.trainingTab.trainingStatus == "TRAINED" ) {
                // 카메라 선택 시,
                if( this.intervalId == null ) {
                    this.startPreviewRecongnition();
                }
            }
        } else {
            // 업로드 선택 시,
            this.stopPreviewRecongnition();
        }
        this.setState({
            previewTab: this.state.previewTab
        });
    }

    handleAddedPreviewUploadImage(imageData) {
        this.state.previewTab.webCam.image = imageData;
        this.setState({
            previewTab: this.state.previewTab
        });

        const s = (p) => {
            p.preload = () => {
                p.pixelDensity( 1 );
                let img  = this.state.previewTab.webCam.image;
                let _img = p.createImage(100, 100);
                _img.loadPixels();
                p.createCanvas( 100, 100 );
                for( let i=0; i < img.length; i++ ) {
                    _img.pixels[ i ] = img[ i ];
                }
                _img.updatePixels();
                p.image( _img, 0, 0, 100, 100 );
                _img.loadPixels();

                let previewData = _img.pixels;

                const extentionObject = this._getExtention();
                if( extentionObject ) {
                    extentionObject.classify( previewData, this._processPreviewInternal );
                }                         
            }
            // p.draw = () => {
                // }
            };
        let t = new p5(s);
        t.remove();        
    }

    // /**
    //  * 예측하기에서, 이미지가 업로드 되었을 때, 분류를 한다.
    //  */
    // handlePreviewPostUploadedImage() {
    //     const s = (p) => {
    //         p.preload = () => {
    //             let img  = this.state.previewTab.webCam.image;
    //             let _img = p.createImage(100, 100);
    //             _img.loadPixels();
    //             p.createCanvas( 100, 100 );
    //             for( let i=0; i < img.length; i++ ) {
    //                 _img.pixels[ i ] = img[ i ];
    //             }
    //             _img.updatePixels();
    //             p.image( _img, 0, 0, 100, 100 );
    //             _img.loadPixels();

    //             let previewData = _img.pixels;

    //             const extentionObject = this._getExtention();
    //             if( extentionObject ) {
    //                 extentionObject.classify( previewData, this._processPreviewInternal );
    //             }                         
    //         }
    //         // p.draw = () => {
    //             // }
    //         };
    //     let t = new p5(s);
    //     t.remove();
    // }

    render () {
        return (
            <MLImageEditorComponent
                onOpen={this.handleOpen}
                onSave={this.handleSave}
                saveFile={this.handleSaveFile}
                onEdit={this.handleEdit}
                onShowWebCam={this.handleShowWebCam}
                onAddClass={this.handleAddClass}
                onBtnOptionClicked={this.handleClassOptionClick}
                onDeleteClass={this.handleDeleteClass}
                onDisableClass={this.handleDisableClass}
                onRemoveAllSample={this.handleRemoveAllSample}
                onDownloadSample={this.handleDownloadSample}
                onHidePopup={this.handleHidePopup}
                onCloseWebCam={this.handleCloseWebCam}
                onBtnImageUploadClicked={this.handleImageUploadHideShow}
                onCloseFileUpload={this.handleImageUploadHideShow}
                onClassNameChange={this.handleClassNameChange}
                onBtnEditNameClicked={this.handleBtnEditName}
                onEditNameDone={this.handleBtnEditName}
                onKeyEnterPress={this.handleKeydownEnter}
                onUploadImage={this.handleUploadImages}
                onBtnNewClicked={this.handleResetPage}
                onBtnAdvancedCollapseClick={this.handleBtnAdvancedCollapseClick}
                onCaptureImage={this.handleWebCamImgCapture}
                
                onSampleImage={this.handleSampleImage}
                onClickTrain={this.handleTrain}

                onChangedEpoch={this.handleEpoch}
                onChangedBatchSize={this.handleBatchSize}
                onChangedLearningRate={this.handleLearningRate}
                onBtnResetDefaults={this.handleResetDefaults}

                onBtnApplyModel={this.handleApplyModel}
                onPreviewWebCam={this.handlePreviewWebCam}

                class={this.state.class}
                trainingTab={this.state.trainingTab}
                previewTab={this.state.previewTab}
                previewResults={this.state.previewResults}

                onDeleteSampleImage={this.handleDeleteImageSample}

                onChangedPreviewInput={this.handlePreviewInput}

                onAddedClassUploadImage={this.handleAddedClassUploadImage}
                onAddedPreviewUploadImage={this.handleAddedPreviewUploadImage}
                // onPreviewPostUploadedImage={this.handlePreviewPostUploadedImage}
                onBtnTermHelp={this.handleBtnTermHelp}
            />
        );
    }
}

MLImageEditor.propTypes = {
    isRtl: PropTypes.bool,
};

const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}.mli`;
};

const mapStateToProps = state => {
    return { 
        isRtl: state.locales.isRtl,
        projectFilename: getProjectFilename(state.scratchGui.projectTitle, projectTitleInitialState)
    }
};

const mapDispatchToProps = dispatch => ({
    onActivateTab: tabIndex => {
        dispatch(activateTab(tabIndex));
    }
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MLImageEditor));