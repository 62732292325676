import PropTypes from 'prop-types';
import React from 'react';
import Box from '../box/box.jsx';
import {injectIntl, intlShape, defineMessages, FormattedMessage} from 'react-intl';
import styles from './bitbrick-info.css';
import bindAll from 'lodash.bindall';

import image_simulator              from './icons/Simulator_base_bitbrick.svg';

import image_sensor_brightness      from './icons/sensor_brightness.svg';         // 0x01;
import image_sensor_distance        from './icons/sensor_distance.svg';           // 0x02;
import image_sensor_button          from './icons/sensor_button.svg';             // 0x03;
import image_sensor_potentiometer   from './icons/sensor_potentiometer.svg';      // 0x04;
import image_sensor_sound           from './icons/sensor_sound.svg';              // 0x05;
import image_sensor_ultrasonic      from './icons/sensor_ultrasonic.svg';         // 0x06;
import image_sensor_vibration       from './icons/sensor_vibration.svg';          // 0x0A;
import image_sensor_userinput       from './icons/sensor_userinput.svg';          // 0x0B;
import image_output_dcmotor         from './icons/output_dcmotor.svg';            // 0x12
import image_output_servomotor      from './icons/output_servomotor.svg';         // 0x13

import icon_port1_on       from './icons/port1_on.svg';
import icon_port2_on       from './icons/port2_on.svg';
import icon_port3_on       from './icons/port3_on.svg';
import icon_port4_on       from './icons/port4_on.svg';
import icon_portA_on       from './icons/portA_on.svg';
import icon_portB_on       from './icons/portB_on.svg';
import icon_portC_on       from './icons/portC_on.svg';
import icon_portD_on       from './icons/portD_on.svg';
import icon_LED_on         from './icons/LED_on.svg';

	// public static const NIL_CONNECTED_SENSORS:int    = 0;
	// public static const LIGHT_SENSOR:int             = 1;
	// public static const IR_SENSOR:int                = 2;
	// public static const TOUCH_SENSOR:int             = 3;
	// public static const POTENTIOMETER_SENSOR:int     = 4;
	// public static const MIC_SENSOR:int               = 5;
	// public static const ULTRASONIC_SENSOR:int        = 6;
	// public static const VIBRATION_SENSOR:int         = 10;
	// public static const EXTENSION:int                = 11;

const BB_ICON_IMAGE     = new Map(
    [
        [ 0x00, "" ],                               // No plugin
        [ 0x01, image_sensor_brightness ],
        [ 0x02, image_sensor_distance ],
        [ 0x03, image_sensor_button ],
        [ 0x04, image_sensor_potentiometer ],
        [ 0x05, image_sensor_sound ],
        [ 0x06, image_sensor_ultrasonic ],
        [ 0x0A, image_sensor_vibration ],
        [ 0x0B, image_sensor_userinput ],
        [ 0x12, image_output_dcmotor ],
        [ 0x13, image_output_servomotor ],
        [ 0x14, "" ],                               // LED
        [ ]
    ]
);

const BB_UI_INFO   = [
    { on_icon : icon_port1_on, on_style : styles.image_port1_on, image_style : styles.image_port1_sensor, val_div_style: styles.val_div_1, val_label_style: styles.val_label_1 },
    { on_icon : icon_port2_on, on_style : styles.image_port2_on, image_style : styles.image_port2_sensor, val_div_style: styles.val_div_2, val_label_style: styles.val_label_2 },
    { on_icon : icon_port3_on, on_style : styles.image_port3_on, image_style : styles.image_port3_sensor, val_div_style: styles.val_div_3, val_label_style: styles.val_label_3 },
    { on_icon : icon_port4_on, on_style : styles.image_port4_on, image_style : styles.image_port4_sensor, val_div_style: styles.val_div_4, val_label_style: styles.val_label_4 },
    { on_icon : icon_portA_on, on_style : styles.image_portA_on, image_style : styles.image_portA_motor,  val_div_style: styles.val_div_5, val_label_style: styles.val_label_5 },
    { on_icon : icon_portB_on, on_style : styles.image_portB_on, image_style : styles.image_portB_motor,  val_div_style: styles.val_div_6, val_label_style: styles.val_label_6 },
    { on_icon : icon_portC_on, on_style : styles.image_portC_on, image_style : styles.image_portC_motor,  val_div_style: styles.val_div_7, val_label_style: styles.val_label_7 },
    { on_icon : icon_portD_on, on_style : styles.image_portD_on, image_style : styles.image_portD_motor,  val_div_style: styles.val_div_8, val_label_style: styles.val_label_8 }
];

class BitBrickInfo extends React.Component {

    constructor( props ) {
        super(props);
        bindAll(this, [
        ]);
    }

    _getImage( type ) {
        let imageSrc    = BB_ICON_IMAGE.get( type );
        if( imageSrc == undefined ) {
            imageSrc    = "";
        }
        return imageSrc;
    }

    shouldComponentUpdate( nextProps ) {
        // if( this.props.bitbrickData.sensorName == nextProps.bitbrickData.sensorName && 
        // this.props.bitbrickData.isConnected == nextProps.bitbrickData.isConnected ) {
        //     for( let i=0; i < this.props.bitbrickData.visItems.length; i++ ) {
        //         if( this.props.bitbrickData.visItems[ i ] == nextProps.bitbrickData.visItems[ i ] ) {
        //         } else {
        //             return true;
        //         }
        //     }
        //     for( let i=0; i < this.props.bitbrickData.imgItems.length; i++ ) {
        //         if( this.props.bitbrickData.imgItems[ i ] == nextProps.bitbrickData.imgItems[ i ] ) {
        //         } else {
        //             return true;
        //         }
        //         if( this.props.bitbrickData.valItems[ i ] == nextProps.bitbrickData.valItems[ i ] ) {
        //         } else {
        //             return true;
        //         }
        //     }
        //     return false;
        // } else {
        //     return true;
        // }
        
        //this.props.name !== nextProps.name      // dummy
        
        return ( true );
    }


    // for( let i = 0; i < 8; i++ ) {
    //     if( portIntoItems[ i ].type == 0x00 ) {
    //         this.bitbrickData.visItems[ i ] = "hidden";
    //         this.bitbrickData.imgItems[ i ] = "";                
    //         this.bitbrickData.valItems[ i ] = "";
    //     } else {
    //         this.bitbrickData.visItems[ i ] = "visible";
    //         this.bitbrickData.imgItems[ i ] = portIntoItems[ i ].type;

    //         if( portIntoItems[ i ].val !== undefined ) {
    //             this.bitbrickData.valItems[ i ] = String( portIntoItems[ i ].val );
    //         } else {
    //             this.bitbrickData.valItems[ i ] = "";
    //         }

    //     }  
    // }

    // if( portIntoItems[ 8 ].type == 0x00 ) {        
    //     this.bitbrickData.visItems[  8 ] = "hidden" ;
    //     this.bitbrickData.valItems[  8 ] = 0x00;
    //     this.bitbrickData.valItems[  9 ] = 0x00;
    //     this.bitbrickData.valItems[ 10 ] = 0x00;
    // } else {
    //     this.bitbrickData.visItems[  8 ] = "visible" ;
    //     this.bitbrickData.valItems[  8 ] = String( portIntoItems[  8 ].val );
    //     this.bitbrickData.valItems[  9 ] = "0"; // String( portIntoItems[  9 ].val );
    //     this.bitbrickData.valItems[ 10 ] = "0"; // String( portIntoItems[ 10 ].val );
    // }

    
    render () {
        // portIntoItems
        //  keys: port, type, val
        
        let     portItems       = this.props.bitbrickData.portIntoItems;

        if( portItems == undefined ) {
            portItems = [ 
                { "port" : "1",   "type" : 0x00, "val": 0x00 },
                { "port" : "2",   "type" : 0x00, "val": 0x00 },
                { "port" : "3",   "type" : 0x00, "val": 0x00 },
                { "port" : "4",   "type" : 0x00, "val": 0x00 }, 
                { "port" : "A",   "type" : 0x00, "val": 0x00 }, 
                { "port" : "B",   "type" : 0x00, "val": 0x00 }, 
                { "port" : "C",   "type" : 0x00, "val": 0x00 }, 
                { "port" : "D",   "type" : 0x00, "val": 0x00 }, 
                { "port" : "E_R", "type" : 0x00, "val": 0x00 },
                { "port" : "E_G", "type" : 0x00, "val": 0x00 },
                { "port" : "E_B", "type" : 0x00, "val": 0x00 }
              ];
        }

        const   iconItems       = [];
        const   visibleItems    = [];
        const   valueItems      = [];

        // Sensor and Motors
        for( let i = 0; i < 8; i++ ) {
            iconItems.push(
                <div key={"icon_" + i.toString()} className={BB_UI_INFO[ i ].image_style}>
                    <img src={this._getImage( portItems[i].type )}/>
                </div>
            );

            let _visibility = ( portItems[i].type == 0x00 ? "hidden" : "visible" );

            visibleItems.push(
                <div key={"visible_" + i.toString()} className={BB_UI_INFO[ i ].on_style} style={{ visibility : _visibility }}>
                    <img src={BB_UI_INFO[ i ].on_icon} />
                </div>
            );
            valueItems.push( 
                <div key={"value_" + i.toString()} className={BB_UI_INFO[ i ].val_div_style}>
                    <label className={BB_UI_INFO[ i ].val_label_style}>{portItems[i].val}</label>
                </div>
            );
        }

        // LED
        const   _visibilityLED  = ( portItems[8].type == 0x00 ? "hidden" : "visible" );
        const   led_red         = portItems[ 8].val;
        const   led_green       = portItems[ 9].val;
        const   led_blue        = portItems[10].val;

        const   _led_onoff  =
                    <div className={styles.image_LED_on} style={{ visibility : _visibilityLED }}>
                        <img src={icon_LED_on} />
                    </div>

        const   _led_color  =
                    <div    
                            className={styles.image_LED_on_color} 
                            style={{ visibility : _visibilityLED }}>
                        <div
                            style={{ borderRadius: '5px', background: "rgb(" + led_red + "," + led_green + "," + led_blue + ")", padding: '10px', width: '2px', height: '2px' }}>
                        </div>
                    </div>

        return (
            <Box className={styles.spriteInfo}>
                <div className={styles.simulatorContainer}>
                    <div className={styles.simulator}>
                        <img id="board" src={image_simulator} />
                    </div>
                    {iconItems}
                    {visibleItems}
                    {valueItems}
                    {_led_onoff}
                    {_led_color}
                </div>
            </Box>
        );
    }
}

BitBrickInfo.propTypes = {
    bitbrickData: PropTypes.shape({
        sensorName: PropTypes.string,
        isConnected: PropTypes.bool,
        visItems: PropTypes.arrayOf(PropTypes.string),
        imgItems: PropTypes.arrayOf(PropTypes.string),
        valItems: PropTypes.arrayOf(PropTypes.string),
    })
};

export default injectIntl(BitBrickInfo);
