import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import ReactModal from 'react-modal';
import VM from 'scratch-vm';
import {injectIntl, intlShape} from 'react-intl';
import bindAll from 'lodash.bindall';

import ErrorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import {
    getIsError,
    getIsShowingProject,
    getIsAnyCreatingNewState
} from '../reducers/project-state';
import {
    activateTab,
    BLOCKS_TAB_INDEX,
    COSTUMES_TAB_INDEX,
    SOUNDS_TAB_INDEX,
    MLIMAGE_TAB_INDEX,
    MLAUDIO_TAB_INDEX,
} from '../reducers/editor-tab';

import {
    closeCostumeLibrary,
    closeBackdropLibrary,
    closeTelemetryModal,
    openExtensionLibrary
} from '../reducers/modals';

import FontLoaderHOC from '../lib/font-loader-hoc.jsx';
import LocalizationHOC from '../lib/localization-hoc.jsx';
import ProjectFetcherHOC from '../lib/project-fetcher-hoc.jsx';
import TitledHOC from '../lib/titled-hoc.jsx';
import ProjectSaverHOC from '../lib/project-saver-hoc.jsx';
import QueryParserHOC from '../lib/query-parser-hoc.jsx';
import storage from '../lib/storage';
import vmListenerHOC from '../lib/vm-listener-hoc.jsx';
import vmManagerHOC from '../lib/vm-manager-hoc.jsx';
import cloudManagerHOC from '../lib/cloud-manager-hoc.jsx';

import GUIComponent from '../components/gui/gui.jsx';
import {setIsScratchDesktop} from '../lib/isScratchDesktop.js';

class GUI extends React.Component {

    constructor (props) {
        super(props);
        bindAll(this, [
            'onExtensionLoaded',
            'startCheckExtension',
            'stopCheckExtension'
        ])
        this.isLoadedMLImage    = false;
    }

    componentDidMount () {
        setIsScratchDesktop(this.props.isScratchDesktop);
        this.props.onStorageInit(storage);
        this.props.onVmInit(this.props.vm);

        this.props.vm.runtime.on('EXTENSION_ADDED', this.onExtensionLoaded )
    }

    onExtensionLoaded(data) {
        if ( data.id == "mlimage" ) {
            // 3 은 MLIMAGE_TAB_INDEX
            this.startCheckExtension( data.id, MLIMAGE_TAB_INDEX );
            this.isLoadedMLImage    = true;
        } else if ( data.id == "mlaudio" ) {
            // 4 은 MLAUDIO_TAB_INDEX
            if( this.isLoadedMLImage ) {
                this.startCheckExtension( data.id, MLAUDIO_TAB_INDEX );
            } else {
                // MLImage가 로드되지 않았을 경우, Tab의 onSelect에서 인덱스를 MLAUDIO_TAB_INDEX로 주면,
                // 포커스가 되지 않는다. 그래서, -1를 하여, index 를 3으로 만든다.
                // 왜냐하면, 0은 코드탭, 1은 모양탭, 2는 소리탭이기 때문에, 3을 해야, MLAudio 탭이 생기고, 포커스를 한다.
                this.startCheckExtension( data.id, MLAUDIO_TAB_INDEX - 1 );     
            }
        } else {
        }
    }

    startCheckExtension( extensionId, tabIndex ) {
        this.intervalId = setInterval(() => {
            let extentionId_seq = this.props.vm.extensionManager._loadedExtensions.get( extensionId );
            if( extentionId_seq !== undefined ) {
                let extentionObject   = this.props.vm.extensionManager.getServices()[ extentionId_seq ];
                if( extentionObject !== null && extentionObject !== undefined ) {
                    this.props.onActivateTab( tabIndex );
                    this.stopCheckExtension();
                }
            }
        }, 500 );
    }

    stopCheckExtension() {
        clearInterval(this.intervalId);
        this.intervalId = null;
    }

    componentDidUpdate (prevProps) {
        if (this.props.projectId !== prevProps.projectId && this.props.projectId !== null) {
            this.props.onUpdateProjectId(this.props.projectId);
        }
        if (this.props.isShowingProject && !prevProps.isShowingProject) {
            // this only notifies container when a project changes from not yet loaded to loaded
            // At this time the project view in www doesn't need to know when a project is unloaded
            this.props.onProjectLoaded();
        }
    }

    render () {
        if (this.props.isError) {
            throw new Error(
                `Error in Scratch GUI [location=${window.location}]: ${this.props.error}`);
        }
        const {
            /* eslint-disable no-unused-vars */
            assetHost,
            cloudHost,
            error,
            isError,
            isScratchDesktop,
            isShowingProject,
            onProjectLoaded,
            onStorageInit,
            onUpdateProjectId,
            onVmInit,
            projectHost,
            projectId,
            /* eslint-enable no-unused-vars */
            children,
            fetchingProject,
            isLoading,
            loadingStateVisible,
            ...componentProps
        } = this.props;
        return (
            <GUIComponent
                loading={fetchingProject || isLoading || loadingStateVisible}
                {...componentProps}
            >
                {children}
            </GUIComponent>
        );
    }
}

GUI.propTypes = {
    assetHost: PropTypes.string,
    children: PropTypes.node,
    cloudHost: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    fetchingProject: PropTypes.bool,
    intl: intlShape,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    isScratchDesktop: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    loadingStateVisible: PropTypes.bool,
    onProjectLoaded: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onStorageInit: PropTypes.func,
    onUpdateProjectId: PropTypes.func,
    onVmInit: PropTypes.func,
    projectHost: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    telemetryModalVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

GUI.defaultProps = {
    isScratchDesktop: false,
    onStorageInit: storageInstance => storageInstance.addOfficialScratchWebStores(),
    onProjectLoaded: () => {},
    onUpdateProjectId: () => {},
    onVmInit: (/* vm */) => {}
};

function _getExtention ( state, extentionId ) {
    let extentionId_seq = state.scratchGui.vm.extensionManager._loadedExtensions.get( extentionId );
    if( extentionId_seq !== undefined ) {
        let extentionObject   = state.scratchGui.vm.extensionManager.getServices()[ extentionId_seq ];
        if( extentionObject !== null && extentionObject !== undefined ) {
            return extentionObject;
        }
    }
    return null;
}

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;

    let isActivatedMLImageExtention = false;
    let extention_mlimage   = _getExtention( state, "mlimage" );
    if( extention_mlimage ) {
        // state.scratchGui.editorTab.activeTabIndex = MLIMAGE_TAB_INDEX;
        if ( state.scratchGui.editorTab.activeTabIndex == MLIMAGE_TAB_INDEX ) {
            extention_mlimage.turnOnOffVideo( { ONOFF : "Off" } );
        }
        isActivatedMLImageExtention = true;

        const creatingNew = getIsAnyCreatingNewState( loadingState );
        if( creatingNew ) {
            // 스크래치 새로 만들기 시, ML Image 확장을 초기화
            extention_mlimage.init();
        }
    }

    let isActivatedMLAudioExtention = false;
    let extention_mlaudio   = _getExtention( state, "mlaudio" );
    if( extention_mlaudio ) {
        // state.scratchGui.editorTab.activeTabIndex = MLAUDIO_TAB_INDEX;
        if ( state.scratchGui.editorTab.activeTabIndex == MLAUDIO_TAB_INDEX ) {
            extention_mlaudio.turnOnOffMic( { ONOFF : "Off" } );
        }
        isActivatedMLAudioExtention = true;

        const creatingNew = getIsAnyCreatingNewState( loadingState );
        if( creatingNew ) {
            // 스크래치 새로 만들기 시, ML Audio 확장을 초기화        
            if ( extention_mlaudio ) {
                extention_mlaudio.init();
            }
        }
    }    


    return {
        activeTabIndex: state.scratchGui.editorTab.activeTabIndex,
        alertsVisible: state.scratchGui.alerts.visible,
        backdropLibraryVisible: state.scratchGui.modals.backdropLibrary,
        blocksTabVisible: state.scratchGui.editorTab.activeTabIndex === BLOCKS_TAB_INDEX,
        cardsVisible: state.scratchGui.cards.visible,
        connectionModalVisible: state.scratchGui.modals.connectionModal,
        costumeLibraryVisible: state.scratchGui.modals.costumeLibrary,
        costumesTabVisible: state.scratchGui.editorTab.activeTabIndex === COSTUMES_TAB_INDEX,
        error: state.scratchGui.projectState.error,
        isError: getIsError(loadingState),
        isFullScreen: state.scratchGui.mode.isFullScreen,
        isPlayerOnly: state.scratchGui.mode.isPlayerOnly,
        isRtl: state.locales.isRtl,
        isShowingProject: getIsShowingProject(loadingState),
        loadingStateVisible: state.scratchGui.modals.loadingProject,
        projectId: state.scratchGui.projectState.projectId,
        soundsTabVisible: state.scratchGui.editorTab.activeTabIndex === SOUNDS_TAB_INDEX,

        // kevin, 2020-06-06, 임시코드
        // mlImageTabVisible: true,
        // mlAudioTabVisible: true,
        mlImageTabVisible: isActivatedMLImageExtention,
        mlAudioTabVisible: isActivatedMLAudioExtention,
        // mlImageTabVisible: state.scratchGui.editorTab.activeTabIndex === MLIMAGE_TAB_INDEX,
        // mlAudioTabVisible: state.scratchGui.editorTab.activeTabIndex === MLAUDIO_TAB_INDEX,        

        targetIsStage: (
            state.scratchGui.targets.stage &&
            state.scratchGui.targets.stage.id === state.scratchGui.targets.editingTarget
        ),
        telemetryModalVisible: state.scratchGui.modals.telemetryModal,
        tipsLibraryVisible: state.scratchGui.modals.tipsLibrary,
        vm: state.scratchGui.vm
    };
};

const mapDispatchToProps = dispatch => ({
    onExtensionButtonClick: () => dispatch(openExtensionLibrary()),
    onActivateTab: tab => dispatch(activateTab(tab)),
    onActivateCostumesTab: () => dispatch(activateTab(COSTUMES_TAB_INDEX)),
    onActivateSoundsTab: () => dispatch(activateTab(SOUNDS_TAB_INDEX)),
    onActivateMLImageTab: () => dispatch(activateTab(MLIMAGE_TAB_INDEX)),
    onActivateMLAudioTab: () => dispatch(activateTab(MLAUDIO_TAB_INDEX)),        
    onRequestCloseBackdropLibrary: () => dispatch(closeBackdropLibrary()),
    onRequestCloseCostumeLibrary: () => dispatch(closeCostumeLibrary()),
    onRequestCloseTelemetryModal: () => dispatch(closeTelemetryModal())
});

const ConnectedGUI = injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(GUI));

// note that redux's 'compose' function is just being used as a general utility to make
// the hierarchy of HOC constructor calls clearer here; it has nothing to do with redux's
// ability to compose reducers.
const WrappedGui = compose(
    LocalizationHOC,
    ErrorBoundaryHOC('Top Level App'),
    FontLoaderHOC,
    QueryParserHOC,
    ProjectFetcherHOC,
    TitledHOC,
    ProjectSaverHOC,
    vmListenerHOC,
    vmManagerHOC,
    cloudManagerHOC
)(ConnectedGUI);

WrappedGui.setAppElement = ReactModal.setAppElement;
export default WrappedGui;
