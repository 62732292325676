import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl, intlShape, defineMessages, FormattedMessage} from 'react-intl';
import styles from './diaboard-info.css';
import bindAll from 'lodash.bindall';

import Box from '../box/box.jsx';

const messages = defineMessages({
    left_button: {
        id: 'gui.diaboard.simulator.left_button',
        description: '',
        defaultMessage: 'left button'
    },
    right_button: {
        id: 'gui.diaboard.simulator.right_button',
        description: '',
        defaultMessage: 'right button'
    },
    color_hues: {
        id: 'gui.diaboard.simulator.color_hues',
        description: '',
        defaultMessage: 'color hues'
    },
    seven_colors: {
        id: 'gui.diaboard.simulator.seven_colors',
        description: '',
        defaultMessage: '7 color'
    },
    ir_sensor: {
        id: 'gui.diaboard.simulator.ir_sensor',
        description: '',
        defaultMessage: 'IR sensor'
    },
    brightness_sensor: {
        id: 'gui.diaboard.simulator.brightness_sensor',
        description: '',
        defaultMessage: 'brightness sensor'
    },
    bpm: {
        id: 'gui.diaboard.simulator.bpm',
        description: '',
        defaultMessage: 'BPM'
    },
    button_pressed: {
        id: 'gui.diaboard.simulator.button_pressed',
        description: '',
        defaultMessage: 'pressed'
    },
    button_not_pressed: {
        id: 'gui.diaboard.simulator.button_not_pressed',
        description: '',
        defaultMessage: 'released'
    },
    color_red: {
        id: 'gui.diaboard.simulator.color_red',
        description: '',
        defaultMessage: 'released'
    },
    color_yellow: {
        id: 'gui.diaboard.simulator.color_yellow',
        description: '',
        defaultMessage: 'yellow'
    },
    color_green: {
        id: 'gui.diaboard.simulator.color_green',
        description: '',
        defaultMessage: 'green'
    },
    color_cyan: {
        id: 'gui.diaboard.simulator.color_cyan',
        description: '',
        defaultMessage: 'cyan'
    },
    color_blue: {
        id: 'gui.diaboard.simulator.color_blue',
        description: '',
        defaultMessage: 'blue'
    },
    color_magenta: {
        id: 'gui.diaboard.simulator.color_magenta',
        description: '',
        defaultMessage: 'magenta'
    },
    color_white: {
        id: 'gui.diaboard.simulator.color_white',
        description: '',
        defaultMessage: 'white'
    },
    color_no: {
        id: 'gui.diaboard.simulator.color_no',
        description: '',
        defaultMessage: 'no color'
    },
});

class DiaboardInfo extends React.Component {

    constructor( props ) {
        super(props);
        bindAll(this, [
        ]);

        this.COLOR_MAP = {   
            0: this.props.intl.formatMessage(messages.color_no),
            1: this.props.intl.formatMessage(messages.color_red),
            2: this.props.intl.formatMessage(messages.color_yellow),
            3: this.props.intl.formatMessage(messages.color_green),
            4: this.props.intl.formatMessage(messages.color_cyan),
            5: this.props.intl.formatMessage(messages.color_blue),
            6: this.props.intl.formatMessage(messages.color_magenta),
            7: this.props.intl.formatMessage(messages.color_white)
        }
        
        this.SENSOR_ITEMS       = [
            { name: this.props.intl.formatMessage(messages.left_button),           val:"S1" },
            { name: this.props.intl.formatMessage(messages.right_button),          val:"S2" },
            { name: this.props.intl.formatMessage(messages.color_hues),            val:"C1" },
            { name: this.props.intl.formatMessage(messages.seven_colors),          val:"S4" },
            { name: this.props.intl.formatMessage(messages.ir_sensor),             val:"S6" },
            { name: this.props.intl.formatMessage(messages.brightness_sensor),     val:"S8" },
            { name: this.props.intl.formatMessage(messages.bpm),                   val:"BPM" }
        ]
    }

    shouldComponentUpdate( nextProps ) {
        return ( true );
    }

    render () {
        // "S1" : "0",        // L버튼상태
        // "S2" : "0",        // R버튼상태
        // "S4" : "0",        // 색상
        // "S6" : "0",        // IR값(적외선)
        // "S7" : "0",        // 1빛센서
        // "S8" : "0",        // 조도값(밝기센서)
        // "C1" : "0",        // HUE 값
        // "C2" : "0",        // saturation 값
        // "C4" : "0",        // intensity 값
        // "V"  : "0"         // 버전값        
        // "{"S1":"F","S2":"F","S4":0,"S6":626,"S7":1,"S8":18,"C1":0,"C2":0,"C4":"0","V":"0","C3":0}"
        let     portItems       = this.props.diaboardData.portIntoItems;
        // console.info( ">> " + portItems );
        if( portItems == undefined ) {
            // default values
            portItems   = {"S1":"F","S2":"F","S4":0,"S6":0,"S7":0,"S8":0,"C1":0,"C2":0,"C4":"0","V":"0","C3":0,"BPM":0};
        }

        const   sensorValues      = [];
        for( let i = 0; i < this.SENSOR_ITEMS.length; i++ ) {
            let  valueName  = "";
            if( this.SENSOR_ITEMS[i].val == 'S1' || 
            this.SENSOR_ITEMS[i].val == 'S2' ) {
                let v       =   portItems[ this.SENSOR_ITEMS[i].val ];
                valueName   =   ( v == 'T' ? this.props.intl.formatMessage(messages.button_pressed) : this.props.intl.formatMessage(messages.button_not_pressed) );
            } else if( this.SENSOR_ITEMS[i].val == 'S4' ) {
                let v       =   portItems[ this.SENSOR_ITEMS[i].val ];
                valueName   =   this.COLOR_MAP[ v ];
            } else {
                let v       =   this.SENSOR_ITEMS[i].val;
                valueName   =   portItems[ v ];
            }
            sensorValues.push(
                <div key={i} className={styles.sensor_label}>
                    <span className={styles.sensor_name_label}>{this.SENSOR_ITEMS[i].name}</span>
                    <span className={styles.sensor_value_label}>{valueName}</span>
                </div>
            );            
        }                
        return (
            <Box className={styles.spriteInfo}>
                {sensorValues}
            </Box>
        );
    }
}

DiaboardInfo.propTypes = {
    diaboardData: PropTypes.shape({
        sensorName: PropTypes.string,
        isConnected: PropTypes.bool,
        visItems: PropTypes.arrayOf(PropTypes.string),
        imgItems: PropTypes.arrayOf(PropTypes.string),
        valItems: PropTypes.arrayOf(PropTypes.string),
    })
};

export default injectIntl(DiaboardInfo);
