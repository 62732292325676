import React from 'react';

import cStyles from './css/common.css';

import * as p5 from "p5";
import { element } from 'prop-types';

class SampleImageComponent extends React.Component {

    constructor (props) {
        super(props);
        this.setDivRef = element => {
            this.divRef = element;
        }
    }

    componentDidMount() {
        const s = (p) => {
            p.setup = () => {
                let img     = this.props.item.data;
                if( img instanceof Uint8Array ) {
                    let _img = p.createImage(100, 100);
                    _img.loadPixels();
                    p.createCanvas( 60, 60 );
                    for( let i=0; i < img.length; i++ ) {
                        _img.pixels[ i ] = img[ i ];
                    }
                    _img.updatePixels();
                    p.image( _img, 0, 0, 60, 60 );
                } else {
                    p.createCanvas( 60, 60 );
                    p.image( img, 0, 0, 60, 60 );
                    p.loadPixels();
                }
            }
            // p.draw = () => {
            // }
        };
        this.myP5 = new p5(s, this.divRef);
    }
    
    render() {
        return (
            <div className={cStyles.wrap_img} ref={this.setDivRef} >
                <button type="button" 
                    className={cStyles.btn_del} 
                    onClick={() => {this.props.onDeleteImageSample(this.props.classIndex, this.props.item.id)}}>
                </button>
            </div>
        );
    }
}

export default SampleImageComponent;