import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, injectIntl, intlShape} from 'react-intl';
import {setProjectTitle} from '../reducers/project-title';

import * as p5 from "p5";

import log from '../lib/log';
import sharedMessages from '../lib/shared-messages';

const JpegCoder = require('jpeg-js');
const PNG = require('@vivaxy/png');

import {
    LoadingStates,
    getIsLoadingUpload,
    getIsShowingWithoutId,
    onLoadedProject,
    requestProjectUpload
} from '../reducers/project-state';

import {
    openLoadingProject,
    closeLoadingProject
} from '../reducers/modals';
import {
    closeFileMenu
} from '../reducers/menus';

/**
 * SBFileUploader component passes a file input, load handler and props to its child.
 * It expects this child to be a function with the signature
 *     function (renderFileInput, handleLoadProject) {}
 * The component can then be used to attach project loading functionality
 * to any other component:
 *
 * <SBFileUploader>{(className, renderFileInput, handleLoadProject) => (
 *     <MyCoolComponent
 *         className={className}
 *         onClick={handleLoadProject}
 *     >
 *         {renderFileInput()}
 *     </MyCoolComponent>
 * )}</SBFileUploader>
 */

const messages = defineMessages({
    loadError: {
        id: 'gui.projectLoader.loadError',
        defaultMessage: 'The project file that was selected failed to load.',
        description: 'An error that displays when a local project file fails to load.'
    }
});

class MLImageFilePreviewUploader extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'getProjectTitleFromFilename',
            'renderFileInput',
            'setFileInput',
            'handleChange',
            'handleClick',
            'onloadend',
            'resetFileInput'
        ]);
        this.fileIndex  = 0;
    }
    componentWillMount () {
    }
    componentDidUpdate (prevProps) {
        // if (this.fileToUpload && this.reader) {
        //     this.reader.readAsArrayBuffer(this.fileToUpload);
        // }
    }
    componentWillUnmount () {
        this.reader = null;
        this.resetFileInput();
    }
    resetFileInput () {
        this.fileToUploads = null;
        if (this.fileInput) {
            this.fileInput.value = null;
        }
    }
    getProjectTitleFromFilename (fileInputFilename) {
        if (!fileInputFilename) return '';
        // only parse title with valid scratch project extensions
        // (.sb, .sb2, and .sb3)
        const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
        if (!matches) return '';
        return matches[1].substring(0, 100); // truncate project title to max 100 chars
    }
    // called when user has finished selecting a file to upload
    handleChange (e) {
        const {
            intl,
            isShowingWithoutId,
            loadingState,
            projectChanged,
            userOwnsProject
        } = this.props;
        const thisFileInput = e.target;
        if (thisFileInput.files) { // Don't attempt to load if no file was selected
            this.fileToUploads = thisFileInput.files;

            for( let i = 0; i < thisFileInput.files.length; i++ ) {
                let reader = new FileReader();
                reader.onloadend = this.onloadend;
                reader.readAsArrayBuffer(thisFileInput.files[ i ]);
            }
        }
    }

    // called when file upload raw data is available in the reader
    onloadend ( reader ) {
        if ( reader ) {
            // ===================================
            let rawImageData =  { data : [], rdata: [], width: 100, height: 100 };
            const   file = this.fileToUploads[ this.fileIndex ];
            if( file.type == "image/jpeg" ) {
                // this.props.onLoadingStarted();
                //const filename = this.fileToUploads && this.fileToUploads.name;
                rawImageData = JpegCoder.decode( reader.target.result,  {useTArray: true } );
                // var jpegImageData = JpegCoder.encode(rawImageData, 150);
                // var blob = new Blob([new Uint8Array(jpegImageData.data)], {type: "application/octet-stream"});
                // downloadBlob(_class.className + '_' + j + '.jpg', blob);       
            } else if( file.type == "image/png" ) {
                // rawImageData = PNG.sync.read( reader.target.result );
                const   imgData     = PNG.decode( reader.target.result );
                rawImageData.width  = imgData.width;
                rawImageData.height = imgData.height;
                rawImageData.data   = imgData.data;
            } else {
            }

            // ====================================================================================
            const s = (p) => {
                p.preload = () => {
                    p.pixelDensity( 1 );
                    p.noCanvas();
                    let img     = p.createImage( rawImageData.width, rawImageData.height );
                    img.loadPixels();
                    let k = 0;
                    for (let i = 0; i < img.height; i++) {
                        for (let j = 0; j < img.width; j++) {
                            img.set( j, i, 
                                p.color( 
                                    rawImageData.data[ k * 4 + 0 ],
                                    rawImageData.data[ k * 4 + 1 ],
                                    rawImageData.data[ k * 4 + 2 ],
                                    rawImageData.data[ k * 4 + 3 ]
                                    )
                                );
                            k++;    // 1 증가
                        }
                    }
                    img.updatePixels();

                    p.createCanvas(img.width*10, img.height*10);  // 일단 캠버스 크기는 넉넉하게 만들어놓고
                    let cropped = p.cropResize(img, 0, 0, img.width, img.height, 100);  // img객체 이미지의 0,0~좌우 넓이만큼의 영역을 224 x 224로 리사이즈하고 crop함.
                    cropped.loadPixels();  // 이미지를 픽셀단위로 읽어서 배열에 저장. 
                    cropped.updatePixels();
                    p.image(cropped, 0, 0);  //0,0위치에 cropped이미지 표시하기

                    rawImageData.rdata  = Uint8Array.from( cropped.pixels );
                }
                // p.setup = () => {
                // }
                // p.draw = () => {
                // }
                p.cropResize = ( image, x, y, w, h, s_size ) => {
                    const cropped_image = p.createImage(s_size, s_size);
                    let x_new, y_new;
                    let dx;
                    let dy;
                    let dw;
                    let dh;
                    if (w < h ) {
                        dx = x;
                        dy = p.round(h/2 - w/2);
                        dw = w;
                        dh = w;
                    } else {
                        dx = p.round(w/2 - h/2);
                        dy = y;
                        dw = h;
                        dh = h;
                    }
                    //print(x, y, w, h, s_size, dx, dy, dw, dh);
                    cropped_image.copy(image, dx, dy, dw, dh, 0, 0, s_size, s_size);
                    return cropped_image;
                }
            };
            let myP5 = new p5(s);
            // ====================================================================================
            myP5.remove();
            myP5 = null;
            this.props.onAddedPreviewUploadImage( rawImageData.rdata );
            this.resetFileInput();
        }
    }
    handleClick () {
        // open filesystem browsing window
        this.fileInput.click();
    }
    setFileInput (input) {
        this.fileInput = input;
    }

    renderFileInput () {
        return (
            <input
                accept=".jpg,.png"
                ref={this.setFileInput}
                style={{display: 'none'}}
                type="file"
                title="열기"
                onChange={this.handleChange}
                name="file"
                id="file"
                //multiple
            />
        );
    }
    render () {
        return this.props.children(this.props.className, this.renderFileInput, this.handleClick);
    }
}

MLImageFilePreviewUploader.propTypes = {
    canSave: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    children: PropTypes.func,
    className: PropTypes.string,
    closeFileMenu: PropTypes.func,
    intl: intlShape.isRequired,
    isLoadingUpload: PropTypes.bool,
    isShowingWithoutId: PropTypes.bool,
    loadingState: PropTypes.oneOf(LoadingStates),
    onLoadingFinished: PropTypes.func,
    onLoadingStarted: PropTypes.func,
    projectChanged: PropTypes.bool,
    requestProjectUpload: PropTypes.func,
    onReceivedProjectTitle: PropTypes.func,
    userOwnsProject: PropTypes.bool,
    vm: PropTypes.shape({
        loadProject: PropTypes.func
    }),
    onAddedPreviewUploadImage: PropTypes.func
};
MLImageFilePreviewUploader.defaultProps = {
    className: ''
};
const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    return {
        isLoadingUpload: getIsLoadingUpload(loadingState),
        isShowingWithoutId: getIsShowingWithoutId(loadingState),
        loadingState: loadingState,
        projectChanged: state.scratchGui.projectChanged,
        vm: state.scratchGui.vm
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    closeFileMenu: () => dispatch(closeFileMenu()),
    onLoadingFinished: (loadingState, success) => {
        dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
        dispatch(closeLoadingProject());
        dispatch(closeFileMenu());
    },
    requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState)),
    onLoadingStarted: () => dispatch(openLoadingProject()),
    onReceivedProjectTitle: title => dispatch(setProjectTitle(title))
});

// Allow incoming props to override redux-provided props. Used to mock in tests.
const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
    {}, stateProps, dispatchProps, ownProps
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(MLImageFilePreviewUploader));