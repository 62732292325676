import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {projectTitleInitialState} from '../reducers/project-title';
import downloadBlob from '../lib/download-blob';
/**
 * Project saver component passes a downloadProject function to its child.
 * It expects this child to be a function with the signature
 *     function (downloadProject, props) {}
 * The component can then be used to attach project saving functionality
 * to any other component:
 *
 * <SB3Downloader>{(downloadProject, props) => (
 *     <MyCoolComponent
 *         onClick={downloadProject}
 *         {...props}
 *     />
 * )}</SB3Downloader>
 */
class SB3Downloader extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'downloadProject'
        ]);
    }
    downloadProject () {
        this.props.saveProjectSb3().then(content => {
            if (this.props.onSaveFinished) {
                this.props.onSaveFinished();
            }
            if( window.bitbrick ) {
                // window.bitbrick.save( this.props.projectFilename, "sb3", new Uint8Array( content ) );
                var _this2 = this;
                var arrayBuffer, uint8Array;
                var fileReader = new FileReader();
                fileReader.onload = function() {
                    arrayBuffer = this.result;
                    uint8Array  = new Uint8Array(arrayBuffer);
                    const data_size = uint8Array.length;
                    const paketSize = 1024 * 32;
                    const s_count = (data_size / paketSize);

                    //if(s_count > 1)
                    {
                        var is_save = true;
                        var vInt = parseInt(s_count);

                        var projectFilename;
                        if(_this2.props.projectFilename)
                            projectFilename = _this2.props.projectFilename;
                        else
                            projectFilename = "Scratch3";

                        for (var i = 0; i < vInt; i++) {

                            const resJson_save = JSON.parse( window.bitbrick.save( projectFilename, "sb3",i, vInt+1, paketSize, new Uint8Array( uint8Array.slice(i*paketSize,(i+1)*paketSize) ) ));

                            if( resJson_save.result ) {
                                // 정상
                            } else {
                                // Save 에러,  안드로이드 저장 권한 체크가 않된 경우 발생         
                                console.error( "save error - " + resJson_save );
                                is_save = false;
                                break;
                            }
                        }
                        if(is_save)    
                        {
                            const resJson_save = JSON.parse( window.bitbrick.save( projectFilename, "sb3",vInt, vInt+1,data_size -  vInt*paketSize, new Uint8Array( uint8Array.slice(vInt*paketSize, data_size) ) ));
                            if( resJson_save.result ) {
                                // 정상
                            } else {
                                // Save 에러,  안드로이드 저장 권한 체크가 않된 경우 발생         
                                console.error( "save error - " + resJson_save );
                            }
                        }
                        

                    }
                   
                   // var array_tttt = new Uint8Array([0x04, 0x06, 0x07, 0x08]);
                   

                };
                fileReader.readAsArrayBuffer(content);
            } else if( window.coalaboard ) {
                window.coalaboard.save( this.props.projectFilename, "sb3", new Uint8Array( content ) );
            } else {
                downloadBlob(this.props.projectFilename, content);
            }
        });
    }
    render () {
        const {
            children
        } = this.props;
        return children(
            this.props.className,
            this.downloadProject
        );
    }
}

const getProjectFilename = (curTitle, defaultTitle) => {
    let filenameTitle = curTitle;
    if (!filenameTitle || filenameTitle.length === 0) {
        filenameTitle = defaultTitle;
    }
    return `${filenameTitle.substring(0, 100)}.sb3`;
};

SB3Downloader.propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    onSaveFinished: PropTypes.func,
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func
};
SB3Downloader.defaultProps = {
    className: ''
};

const mapStateToProps = state => ({
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
    projectFilename: getProjectFilename(state.scratchGui.projectTitle, projectTitleInitialState)
});

export default connect(
    mapStateToProps,
    () => ({}) // omit dispatch prop
)(SB3Downloader);
