import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';

import DiaboardInfoComponent from '../components/diaboard/diaboard-info.jsx';

class DiaboardInfo extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            '_onConnected',
            '_onDisconnected',
            '_onMessageCallback'
            ]
        );
        this.diaboardData = this.initDiaboardData();
    }

    initDiaboardData() {
        return {
            sensorName : "Diaboard",
            isConnected : false,
         };
    }
    
    componentWillMount() {
        this.props.vm.addListener('PERIPHERAL_CONNECTED_DIABOARD_LINK', this._onConnected);
        this.props.vm.addListener('PERIPHERAL_DISCONNECTED_DIABOARD_LINK', this._onDisconnected);
    }

    componentWillUnmount() {
        this.props.vm.removeListener('PERIPHERAL_CONNECTED_DIABOARD_LINK',    this._onConnected);
        this.props.vm.removeListener('PERIPHERAL_DISCONNECTED_DIABOARD_LINK', this._onDisconnected);

        let extentionId = this.props.vm.extensionManager._loadedExtensions.get( "diaboard" );
        if( extentionId !== undefined ) {
            let diaboardExtentionObject   = this.props.vm.extensionManager.getServices()[ extentionId ];
            if( diaboardExtentionObject !== null && diaboardExtentionObject !== undefined ) {
                diaboardExtentionObject.removeListener( "message", this._onMessageCallback );
                diaboardExtentionObject.disconnect();
            }
        }
    }

    /**
     * 하드웨어가 연결(즉, COM3 연결) 되었을 때, CallBack 함수
     */
    _onConnected() {
        this.diaboardData = this.initDiaboardData();
        this.diaboardData.isConnected = true;
        this.setState( { key: Math.random() } );

        let extentionId = this.props.vm.extensionManager._loadedExtensions.get( "diaboard" );
        if( extentionId !== undefined ) {
            let diaboardExtentionObject   = this.props.vm.extensionManager.getServices()[ extentionId ];
            if( diaboardExtentionObject !== null && diaboardExtentionObject !== undefined ) {
                diaboardExtentionObject.addListener( "message", this._onMessageCallback );
            }
        }
    }

    /**
     * 하드웨어가 연결해제(즉, COM3 연결해제) 시, CallBack 함수.
     */
    _onDisconnected() {
        let extentionId = this.props.vm.extensionManager._loadedExtensions.get( "diaboard" );
        if( extentionId !== undefined ) {
            let diaboardExtentionObject   = this.props.vm.extensionManager.getServices()[ extentionId ];
            if( diaboardExtentionObject !== null && diaboardExtentionObject !== undefined ) {
                diaboardExtentionObject.removeListener( "message", this._onMessageCallback );
            }
        }
        this.diaboardData = this.initDiaboardData();
        this.setState( { key: Math.random() } );
    }

    _onMessageCallback( portIntoItems ) {
        this.diaboardData.portIntoItems = portIntoItems;
        this.setState( { key: Math.random() } );
    }

    render () {
        return (
            <DiaboardInfoComponent
                {...this.props}
                diaboardData={this.diaboardData}
            />
        );
    }
}

DiaboardInfo.propTypes = {
    ...DiaboardInfoComponent.propTypes,
    diaboardData: PropTypes.shape({
        sensorName: PropTypes.string,
        isConnected: PropTypes.bool
    })
};

export default DiaboardInfo;
